/////////////////////////
// Tab
/////////////////////////

.tab-wrapper {
  // Tab Navigation

  .nav {
    &-tabs {
      border-bottom: solid 2px var(--neutral-600);
      position: relative;

      &:after {
        content: '';
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);
      }
    }

    &-link {
      border: none;
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: var(--text-white);
      font-weight: 600;
      border-radius: 0rem;

      &.active {
        color: var(--text-white);
        border-radius: 0.5rem 0.5rem 0rem 0rem;
        border-top: 1px solid var(--border-neutral);
        border-right: 1px solid var(--border-neutral);
        border-left: 1px solid var(--border-neutral);
        background: var(--neutral-800);
      }

      @include media-breakpoint-down(md) {
        & {
          width: 100%;
          padding: 0.5rem 0.5rem;
          font-size: 0.6875rem;
          background: var(--background-inverse);
        }
      }
    }

    &-item {
      @include media-breakpoint-down(md) {
        & {
          width: 50%;
        }
      }
    }
  }

  // With Mobile Select

  &.responsive {
    & > .form-selectbox {
      margin-bottom: 1rem;
    }
  }

  &.switch {
    &-xl {
      @include media-breakpoint-up(xl) {
        & > .form-selectbox {
          display: none !important;
        }
      }

      @include media-breakpoint-down(xl) {
        .nav {
          &-tabs {
            display: none !important;
          }
        }
      }
    }

    &-lg {
      @include media-breakpoint-up(lg) {
        & > .form-selectbox {
          display: none !important;
        }
      }

      @include media-breakpoint-down(lg) {
        .nav {
          &-tabs {
            display: none !important;
          }
        }
      }
    }

    &-md {
      @include media-breakpoint-up(md) {
        & > .form-selectbox {
          display: none !important;
        }
      }

      @include media-breakpoint-down(md) {
        .nav {
          &-tabs {
            display: none !important;
          }
        }
      }
    }
  }

  // Tab Content

  .tab {
    &-content {
      background: inherit;
      padding: 1.5rem;

      @include media-breakpoint-down(md) {
        padding: 1.5rem 1rem;
      }

      .table {
        tbody {
          td {
            border-bottom-color: var(--neutral-600);

            &.child-expand-tr {
              box-shadow: none;
              background: rgba(#14171a, 0.35) !important;
            }
          }
        }

        @include media-breakpoint-down(md) {
          & {
            tbody {
              tr {
                border-color: var(--neutral-600);
              }
            }

            .expand-cell,
            .expand-cell-header {
              background: rgba(#14171a, 0.35) !important;
            }
          }
        }
      }
    }
  }

  &.content-gap-none {
    .tab-content {
      padding: 1.5rem 0 0;
    }
  }
}
