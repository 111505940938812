//////////////////////////////////////
// 404 & Coming Soon Pages
//////////////////////////////////////

.site {
    &__no-route {
        min-height: 100vh;
        display: flex;
        align-items: center;
        padding: 3rem 0;
        text-align: center;

        .logo  {
            display: inline-flex;
            vertical-align: top;
            @include font__size(40px);
            @include line__height(50px);
            font-weight: $font-weight--300;
            color: $color-white;

            span {
                font-weight: $font-weight--700;
                color: $color-brand;
            }
        }
        
        .graphics {
            &__404 {
                height: 2rem;
                width: auto;
            }
        }

        .text {
            &__coming-soon {
                font-weight: $font-weight--700;
                @include font__size(80px);
                @include line__height(98px);
                letter-spacing: 1rem;
                text-transform: uppercase;
                text-align: center;

                @include media-breakpoint-down(md) {
                    & {
                        @include font__size(50px);
                        @include line__height(70px);
                    }
                }
            }
        }
    }
}