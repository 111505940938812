.Toastify__toast {
  background: var(--background-light);
  color: var(--text-white);

  &--error {
    .Toastify__progress-bar--error {
      background: var(--error-600);
    }
    div svg {
      fill: var(--error-600);
    }
  }
  &--info {
    .Toastify__progress-bar--info {
      background: var(--info-100);
    }
    div svg {
      fill: var(--info-100);
    }
  }
  &--success {
    .Toastify__progress-bar--success {
      background: var(--success-600);
    }
    div svg {
      fill: var(--success-600);
    }
  }
  .Toastify__close-button--light {
    color: var(--text-white);
    opacity: 1;
  }
}
