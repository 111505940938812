/////////////////////////
// Breadcrumb
/////////////////////////

.breadcrumb {
  display: flex;
  align-items: center;
  color: var(--text-white);
  padding: 0.5rem 0;
  margin-top: 1rem;
  margin-bottom: 0;

  @include media-breakpoint-down(md) {
    & * {
      white-space: normal;
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  button {
    font-weight: 600;
    color: var(--text-white);
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    background: none;
    display: flex;
    align-items: center;
    @include transition($transition);

    &:hover {
      color: var(--brand-primary);
    }
  }

  a {
    font-weight: 600;
    color: var(--text-white);
    @include transition($transition);

    &:hover {
      color: var(--brand-primary);
    }
  }

  &-item {
    & + .breadcrumb-item {
      &:before {
        content: "|";
        color: var(--text-white);
      }
    }

    &.active {
      color: var(--text-white);
    }
  }
}

.breadcrumbs-container {
  .active {
    color: #fff;
  }

  .inactive {
    color: var(--neutral-400);
  }
}