/////////////////////////
// Form
/////////////////////////

.required {
  padding-left: 0.25rem;
}

.form {
  &-control {
    background-color: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;

    color: var(--text-weak);

    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border: 0;
    border-bottom: 1px solid var(--border-neutral);
    border-radius: 0;

    box-shadow: 0px 1px 2px 0px rgba(16, 40, 34, 0.05);

    &.form-control--sm {
      // this is our default size
    }

    &.form-control--md {
      padding: 0.6125rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &.form-control--lg {
      padding: 1rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    &:focus {
      color: var(--text-weak);
      background-color: inherit;
      border-bottom-color: var(--border-primary-active);
      box-shadow: none;
    }

    &::placeholder {
      font-style: italic;
      font-weight: 400;
      border-bottom-color: var(--border-neutral);
    }

    &:disabled {
      color: var(--text-disabled);
      background-color: inherit;
      border-bottom-color: var(--border-neutral-disabled);
    }

    &:has(+ .form-error) {
      border-bottom-color: var(--border-error);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    & + .input-icon {
      margin-left: -1rem;
    }
  }

  .input-icon {
    margin-right: -1rem;
  }

  .input-icon + .form-control {
    padding-left: 1rem;
  }


  &-upload {
    @include font__size(13px);
    @include line__height(20px);
    font-weight: 400;
    color: var(--text-white);
    border-radius: 0.25rem;
    border: 1px dashed var(--border-primary-active);

    padding: 0.75rem 1.25rem;
    height: 9rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    [type='file'] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      z-index: 1;
    }
  }

  &-label {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: var(--text-white);

    &:has(+ .form-control:disabled) {
      color: var(--text-disabled);
    }

    a {
      color: var(--text-white);

      &:hover {
        color: var(--brand-primary);
      }
    }
  }

  // goes below the input line
  &-text {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    margin-top: 0.25rem;
    color: var(--text-weak);

    &:has(+ .form-control:disabled) {
      color: var(--text-disabled);
    }

    a {
      color: var(--text-white);

      &:hover {
        color: var(--brand-primary);
      }
    }
  }

  &-text-none {
    display: block;
    height: 1.125rem;
    margin-top: 0.25rem;
  }

  &-error {
    color: var(--text-error);
    margin-bottom: 0;

    &.multiline {
      align-items: flex-start;

      &:before {
        margin-top: 3px;
        margin-right: 0.5rem;
      }
    }
  }

  &-info {
    position: relative;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--text-weak);
    font-weight: 400;
    display: block;
    padding-left: 1.5rem;

    &:before {
      font-family: 'Material Symbols Outlined';
      content: '\e88e';
      font-size: 1.25rem;
      position: absolute;
      left: 0;
    }
  }

  &-checkbox,
  &-radio {
    position: relative;
    display: flex;
    align-items: flex-start;

    .icon {
      width: 1rem;
      height: 1rem;
      position: relative;
      @include transition($transition);
      flex-shrink: 0;
      border-radius: 0.125rem;
      border: 1px solid var(--border-neutral);
      background: var(--background-dark);

      &:after {
        @include materialsymbol('\E5CA');
        width: 100%;
        height: 100%;
        background: var(--brand-primary);
        background-attachment: fixed;
        background-position: center;
        position: absolute;
        border-radius: 0.125rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        @include transition($transition);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    [type='checkbox'],
    [type='radio'] {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;

      &:hover {
        & + .icon {
          background-color: var(--background-primary-hover);
          border: 1px solid var(--border-primary-active);
        }

        &:checked {
          & + .icon {
            &:after {
              background-color: var(--background-primary-hover);
            }
          }
        }
      }

      &:checked {
        & + .icon {
          &:after {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }

    .form-label {
      margin: 0;
      margin-left: 0.75rem;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    & + .form-info {
      margin-left: 2rem;
    }

    &__inner {
      position: relative;
      display: flex;
    }
  }

  &-radio {
    .icon {
      border-radius: 0.625rem;
      border: 1px solid var(--border-primary);
      background: var(--background-inverse);
      width: 1.25rem;
      height: 1.25rem;
      flex-shrink: 0;

      &:after {
        border-radius: 0.625rem;
        @include materialsymbol('\e837');
        content: '\e837';
        display: flex;
        justify-content: center;
        font-size: 24px;
        color: var(--brand-primary);
        border: 1px solid var(--border-primary-active);
        background: var(--background-primary-active);
      }
    }

    [type='radio'] {
      &:hover {
        &:checked {
          & + .icon {
            &:after {
              background-color: var(--background-primary);
            }
          }
        }
      }
    }
  }

  &-checkbox-group,
  &-radio-group {
    display: flex;
    flex-wrap: wrap;

    & > .form-checkbox,
    & > .form-radio {
      margin-right: 3rem;
    }

    @include media-breakpoint-down(md) {
      & {
        flex-direction: column;

        & > .form-checkbox,
        & > .form-radio {
          margin-right: 0;

          &:not(:first-child) {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  &-color {
    width: 34px;
    height: 34px;
    @include radius(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include media-breakpoint-down(md) {
      & {
        width: 33px;
        height: 33px;
      }
    }

    [type='radio'] {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }

    .icon {
      img {
        height: 1rem;
        width: auto;
        display: block;
        opacity: 0;
        @include transition($transition);
      }
    }

    [type='radio']:checked ~ .icon {
      img {
        opacity: 1;
      }
    }
  }

  &-icon {
    position: relative;

    .icon {
      position: absolute;
      left: 1.25rem;
      top: 50%;
      @include translate(0, -50%);

      img {
        height: 1rem;
        width: auto;
        display: block;
      }

      &-email {
        img {
          height: 0.875rem;
        }
      }

      &-reset {
        left: auto;
        right: 1.25rem;
        cursor: pointer;

        img {
          height: 0.875rem;
        }
      }
    }

    .form-control {
      padding-left: 3rem;
    }
  }

  &-search {
    @include media-breakpoint-up(xl) {
      & {
        min-width: 360px;
      }
    }

    &-fluid {
      .form-search {
        @include media-breakpoint-up(md) {
          & {
            min-width: auto;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      & {
        width: 100%;
      }
    }

    &.has-reset {
      .form-control {
        padding-right: 3rem;
      }
    }
  }

  &-select {
    position: relative;
    padding: 0;
    -moz-padding-start: 0;
    background: none;
    border: none;
    border-radius: 0;
    transition: none;
    appearance: none;
    width: auto;

    &:after {
      @include fontawesome('\f107');
      @include font__size(16px);
      @include line__height(22px);
      color: var(--text-white);
      position: absolute;
      right: 1.25rem;
      top: 50%;
      @include translate(0, -50%);
    }

    .form-control {
      padding-right: 3rem;
    }
  }

  &-switcher {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
      width: 40px;
      height: 20px;
      background: var(--text-white);
      @include radius(1.5rem);
      margin: 0;
      font-size: 0;
      line-height: 0;
      position: relative;
      cursor: pointer;
      @include transition($transition);

      &:after {
        content: '';
        width: 1rem;
        height: 1rem;
        @include radius(50%);
        background: var(--neutral-700);
        position: absolute;
        left: 0;
        top: 0;
        margin: 2px;
        @include transition($transition);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      }
    }

    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }

    input:checked ~ label {
      background: var(--brand-primary);

      &:after {
        background: var(--text-white);
      }
    }

    input:checked ~ label:after {
      left: calc(100% - 20px);
    }
  }

  &-textarea {
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;
    height: 9rem;
    border-radius: 0.5rem;
    border: 1px solid var(--border-neutral);
    background: inherit;
    box-shadow: 0px 1px 2px 0px rgba(16, 40, 34, 0.05);
    font-weight: 400;

    &:focus {
      border: 1px solid var(--border-primary-active);
    }

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--neutral-600);
      outline: none;
      @include radius(1rem);
    }
  }

  &-block {
    border-radius: 0.25rem;
    border: 1px solid var(--border-neutral);
    gap: 0.75rem;
    padding: 1rem;
    background-color: var(--background-inverse);

    @include media-breakpoint-down(md) {
      & {
        padding: 1.5rem 1rem;
      }
    }

    &:after {
      content: '';
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      top: -3px;
      box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);
    }

    .list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        padding-left: 1.25rem;

        & + li {
          margin-top: 0.125rem;
        }

        &:before {
          @include fontawesome('\f111');
          font-size: 0.5em;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .table {
      tbody {
        td {
          border-bottom-color: var(--neutral-600);
        }
      }

      @include media-breakpoint-down(md) {
        & {
          tbody {
            tr {
              border-color: var(--neutral-600);
            }
          }

          .expand-cell,
          .expand-cell-header {
            background: rgba($gray-900, 0.35) !important;
          }
        }
      }
    }
  }

  &-chip {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    padding: 0.25rem;
    padding-left: 0;
    @include transition($transition);
    color: var(--text-white);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    border-radius: 3.125rem;
    background: var(--background-light);

    &.cursor-pointer {
      &:hover {
        background: darken($gray-600, 5%);
      }
    }

    @include media-breakpoint-down(md) {
      & {
        @include radius(0.25rem);
        padding: 0.5rem;
      }
    }

    & > span {
      padding: 0 1rem;

      @include media-breakpoint-down(md) {
        & {
          padding: 0 0.5rem 0 0.25rem;
        }
      }
    }

    .inner {
      margin-left: auto;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      list-style: none;
      padding: 0;

      li {
        &:not(:first-child) {
          margin-left: 0.25rem;
        }
      }
    }

    button {
      border: none;
      background: var(--neutral-800);
      outline: none;
      box-shadow: none;
      padding: 0;
      margin: 0;
      display: flex;
      width: 2rem;
      height: 2rem;
      @include radius(50%);
      @include transition($transition);

      @include media-breakpoint-down(md) {
        & {
          @include radius(0.25rem);
        }
      }

      svg {
        height: 1rem;
        width: auto;
        fill: var(--text-white);
        margin: auto;
        @include transition($transition);
      }

      &:hover {
        background: var(--neutral-700);
      }
    }

    &.disabled {
      background: darken($gray-600, 5%);
      color: rgba($color-white, 0.5);

      & > span {
        user-select: none;
      }
    }

    &.raw {
      padding: 0.625rem 1rem;

      & > span {
        padding: 0;
      }
    }

    &-group {
      & > * {
        margin: 0.25rem 0.5rem 0.25rem 0;

        @include media-breakpoint-down(md) {
          & {
            margin: 0;
            display: flex;

            &.form-chip:not(:first-child) {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }

    &.has-children {
      display: flex;
      @include radius(10px);
      display: block;
      padding: 0.75rem 1rem 0.5rem;

      & > span {
        display: block;
        padding: 0;
        margin: 0 0 0.5rem;
        font-weight: 600;
      }

      .inner {
        display: flex;
        align-items: center;
        border-top: dashed 1px var(--neutral-500);
        padding-top: 0.75rem;
      }

      ul {
        margin-left: auto;
        flex-shrink: 0;
      }

      .form {
        &-label {
          font-weight: 500;
          flex-shrink: 0;
          margin: 0;
          margin-right: 1rem;
        }

        &-control {
          margin-right: 1rem;
          width: 100px;
        }
      }
    }
  }

  &-phone-input {
    color: var(--text-white);
    border-radius: 0.25rem;
    outline: none;
    border: 1px solid var(--border-neutral);
    display: flex;
    align-items: center;

    &:focus {
      box-shadow: 0px 0px 4px 5px rgba(108, 117, 125, 0.15);
      color: var(--text-white);
    }

    .PhoneInputCountry {
      height: 44px;
      padding: 1.25rem;
      margin-right: 0;

      .PhoneInputCountryIcon {
        background: none;
        border: none;
        box-shadow: none;
        height: 1.25rem;
        width: 30px;
        margin-right: 0.75rem;
      }

      .PhoneInputCountrySelectArrow {
        border-color: var(--text-white);
        width: 0.5rem;
        height: 0.5rem;
        margin: -0.25rem 0 0;
        opacity: 1;
        @include transition($transition);
      }

      .PhoneInputCountrySelect {
        background: var(--neutral-700);
        color: var(--text-white);

        &:focus {
          & + .PhoneInputCountryIcon {
            box-shadow: none;

            & + .PhoneInputCountrySelectArrow {
              color: var(--text-white);
            }
          }
        }
      }
    }

    .PhoneInputInput {
      @include font__size(13px);
      @include line__height(20px);
      font-weight: 400;
      color: var(--text-white);
      border: none;
      outline: none;
      box-shadow: none;
      background: none;
      padding: 0;
      padding-right: 1.25rem;

      &::-webkit-input-placeholder {
        color: var(--text-white);
        opacity: 0.75;
      }

      &:-ms-input-placeholder {
        color: var(--text-white);
        opacity: 0.75;
      }

      &::placeholder {
        color: var(--text-white);
        opacity: 0.75;
      }
    }
  }
}

body.user-is-tabbing {
  .form-checkbox [type='checkbox'],
  .form-radio [type='radio'] {
    &:focus {
      & + .icon {
        outline: none;
        box-shadow: 0 0 0 4px var(--border-primary-focus);
      }
    }
  }
}
