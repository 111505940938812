.list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding-left: 1.25rem;

    &+li {
      margin-top: 0.125rem;
    }

    &:before {
      @include fontawesome('\f111');
      font-size: 0.5em;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &.square {
    li {
      padding-left: 1.75rem;

      &+li {
        margin-top: 0.5rem;
      }

      &:before {
        @include fontawesome('\f04d');
      }
    }
  }
}