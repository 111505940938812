/////////////////////////
// Carousel
/////////////////////////

.slick {
  &-prev,
  &-next {
    height: 4rem;
    width: 1.75rem;
    background: none;
    overflow: hidden;
    z-index: 1;

    &::before {
      font-size: 24px !important;
      line-height: 32px !important;
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      width: 4rem;
      height: 4rem;
      @include radius(50%);
      background: var(--neutral-600);
      position: absolute;
      top: 0;
      @include transition($transition);
    }

    &:hover {
      &:after {
        background: var(--neutral-700);
      }
    }
  }
  &-prev {
    left: 0;

    &::before {
      @include fontawesome('\f104');
      margin-right: 4px;
    }

    &:after {
      right: 0;
    }
  }
  &-next {
    right: 0;

    &::before {
      @include fontawesome('\f105');
      margin-left: 4px;
    }

    &:after {
      left: 0;
    }
  }
}

// MSEL Board

.carousel {
  &__msel-board {
    .slick {
      &-prev {
        left: -1.5rem;
      }

      &-next {
        right: -1.5rem;
      }

      @include media-breakpoint-down(md) {
        &-prev {
          left: -1rem;
        }

        &-next {
          right: -1rem;
        }
      }
    }
  }
}

.board-item {
  padding: 0 0.5rem;

  &__title {
    display: flex;
    align-items: center;
    @include radius(10px 10px 0 0);
    padding: 0.75rem 1rem;

    h5 {
      margin: 0;
      margin-right: 1rem;
    }

    span {
      flex-shrink: 0;
      margin-left: auto;
      font-weight: 600;
    }
  }

  &__content {
    background: var(--neutral-700);
    @include radius(0 0 10px 10px);
    height: 819px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      outline: none;
      @include radius(6px);
    }
  }

  &__block {
    padding: 1rem;

    &:not(:first-child) {
      border-top: solid 1px var(--neutral-600);
    }
  }
}
