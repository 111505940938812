/////////////////////////
// Loader
/////////////////////////

.loader {
  width: 100%;
  height: 100%;
  background: rgba($gray-900, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &.maincontent {
    position: static;
    height: 100%;
    width: 100%;
  }

  .inner {
    & > div {
      -webkit-animation: loader 1.5s infinite;
      animation: loader 1.5s infinite;
      background-color: $color-white;
      height: 20px;
      left: 50%;
      margin-top: -10px;
      opacity: 0;
      position: absolute;
      top: 50%;
      width: 20px;

      &:nth-of-type(1) {
        -webkit-animation-delay: 0.25s;
        animation-delay: 0.25s;
        margin-left: -10px;
      }

      &:nth-of-type(2) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
        margin-left: 15px;
      }

      &:nth-of-type(3) {
        -webkit-animation-delay: 0.75s;
        animation-delay: 0.75s;
        margin-left: -35px;
      }

      &:nth-of-type(4) {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        margin-left: 40px;
      }

      &:nth-of-type(5) {
        -webkit-animation-delay: 1.25s;
        animation-delay: 1.25s;
        margin-left: -60px;
      }
    }
  }
}
