/////////////////////////
// Typography
/////////////////////////

/* Typeface */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap');

html {
  font-size: 16px;
}

body {
  font-family: 'Open Sans', sans-serif;
  /* NOTE about body font size: This is set at the Text-Sm Foundations size since that is the predominant size used
  *   in the Momentum designs.
  */
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 400;
  color: var(--text-white);
  -webkit-font-smoothing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 1rem;
  color: var(--text-white);
}

h1 {
  @include heading(36px, 44px, $font-weight--700);

  @include media-breakpoint-down(md) {
    & {
      @include heading(30px, 38px, $font-weight--700);
    }
  }
}

h2 {
  @include heading(30px, 38px, $font-weight--700);

  @include media-breakpoint-down(md) {
    & {
      @include heading(26px, 34px, $font-weight--700);
    }
  }
}

h3 {
  @include heading(24px, 32px, $font-weight--700);

  @include media-breakpoint-down(md) {
    & {
      @include heading(22px, 30px, $font-weight--700);
    }
  }
}

h4 {
  @include heading(20px, 30px, $font-weight--700);
}

h5 {
  @include heading(18px, 28px, $font-weight--700);
}

h6 {
  @include heading(14px, 24px, $font-weight--700);
}

.p {
  &--xl {
    @include font__size(24px);
    @include line__height(34px);

    @include media-breakpoint-down(md) {
      & {
        @include font__size(20px);
        @include line__height(30px);
      }
    }
  }

  &--l {
    @include font__size(18px);
    @include line__height(28px);

    @include media-breakpoint-down(md) {
      & {
        @include font__size(16px);
        @include line__height(26px);
      }
    }
  }
}

/** Text sizes from foundations */
.txt {
  &--xl {
    font-size: 1.25rem;
    line-height: 1.875;
  }

  &--lg {
    font-size: 1.125rem;
    line-height: 1.75;
  }

  &--md {
    font-size: 1rem;
    line-height: 1.5;
  }

  &--sml {
    font-size: 0.875rem;
    line-height: 1.25;
  }

  &--xsml {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.display-txt {
  &--lg {
    font-size: 3rem;
    line-height: 3.75rem;
  }

  &--md {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }

  &--sm {
    font-size: 1.875rem;
    line-height: 3.275rem;
  }

  &--xs {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.txt--bold {
  font-weight: 700;
}

.txt--semibold {
  font-weight: 600;
}


a {
  color: var(--brand-primary);

  &:hover {
    color: var(--background-primary-hover);
  }
}