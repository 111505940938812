.fullscreen--modal-dialog {
  width: 100vw;
  height: 100vh;
  background: #000;

  .modal-content {
    padding: 25px 100px;
    background: #000;
  }

  .modal-body {
    padding: 0px;
    background-color: var(--neutral-900);
  }
}

.fullscreen--modal-header {
  background: #000;
  border: none;
  padding: 20px 0px;

  .modal-title {
    margin: 0;
    font-weight: normal;
  }

  .fullscreen--close-btn {
    background-color: var(--neutral-900);
    outline: none;
    border: none;
    padding: 8px 6px 4px 6px;

    .icon {
      color: #fff;
      margin-top: 2px;
    }
  }
}
