/////////////////////////
// Pagination
/////////////////////////

.pagination {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1rem;
  gap: 0.625rem;

  li {
    font-weight: 400;
    padding: 0.375rem;
    /* reset padding from bootstrapTable */
    --#{$prefix}pagination-padding-x: 0rem;
    --#{$prefix}pagination-padding-y: 0rem;

    &.selected,
    &.active {
      border-radius: 0.125rem !important;
      background-color: var(--background-primary);
      a {
        color: var(--text-white) !important;
        background-color: var(--background-primary);
      }
    }

    a {
      text-decoration: none;
      color: var(--text-weak) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      box-shadow: none;
      line-height: 1.25rem;

      &:hover {
        background: none;
      }

      &:focus {
        box-shadow: none;
        background: none;
      }
    }

    &.previous {
      a {
        @include font-size(0px);
        &:after {
          @include fontawesome('\f104');
          @include font-size(20px);
          display: block;
        }

        &:hover {
          background: none;
          color: var(--text-white) !important;
        }
      }
    }

    &.next {
      a {
        @include font-size(0px);

        &:after {
          @include fontawesome('\f105');
          @include font-size(20px);
          display: block;
        }
        &:hover {
          background: none;
          color: var(--text-white) !important;
        }
      }
    }

    &.disabled {
      --#{$prefix}pagination-disabled-bg: none !important;
      color: var(--text-weak) !important;
      a {
        cursor: default;
        &:hover {
          background: none;
          color: var(--text-weak) !important;
        }
      }
    }
  }
}
