/////////////////////////
// Global
/////////////////////////

body {
  background: var(--background-primary-dark);
  min-height: 100vh;

  &:has(.site-main__social) {
    background: none;
  }
}

::-webkit-scrollbar {
  width: 0.25rem;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-600;
  outline: none;
  @include radius(1rem);
}

hr {
  border-top: solid 2px $gray-700;
  margin: 2rem 0;
  opacity: 1;

  &.dashed {
    border-style: dashed;
  }

  &.thin {
    border-top-width: 1px;
  }

  &.g-600 {
    border-color: $gray-600;
  }

  &.w-10 {
    border-color: rgba($color-white, 0.1);
  }

  &.w-25 {
    border-color: rgba($color-white, .25);
    width: 100% !important;
  }
}

.rounded {
  @include radius(10px !important);
}

.rounded-5 {
  @include radius(5px !important);
}

.rounded-5-mobile-only {
  @include media-breakpoint-down(md) {
    @include radius(5px !important);
  }
}

.anchor {
  color: $color-brand !important;
  text-decoration: underline !important;
  cursor: pointer;
  font-weight: $font-weight--500;

  &:hover {
    color: $color-brand !important;
    text-decoration: none !important;
  }

  &--white {
    color: $color-white !important;

    &:hover {
      color: $color-white !important;
    }
  }
}

// Basic List

.ol,
.ul {
  list-style-position: inside;
  margin: 0 0 1rem;
  padding: 0;

  li {
    &:not(:first-child) {
      margin-top: 0.25rem;
    }
  }
}


// Nav

.nav {
  &__form-color {
    margin: 0 -0.375rem !important;

    li {
      margin: 0.375rem;
    }
  }

  &__form-icons {
    flex-wrap: wrap;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-600;
      outline: none;
      @include radius(1rem);
    }

    li {
      width: calc(100% / 10);
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      svg {
        height: 1.5rem;
        width: auto;
        display: block;
        @include transition($transition);
        cursor: pointer;
      }

      &:hover {
        svg {
          @include scale(1.25);
        }
      }
    }
  }

  &__prev-next {
    display: flex;
    align-items: center;

    li {
      button {
        border: none;
        background: $gray-700;
        outline: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        @include transition($transition);
        color: $color-white;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font__size(16px);

        &:hover {
          background: $gray-900;
        }
      }

      &:first-child {
        button {
          @include radius(5px 0 0 5px);
        }
      }

      &:last-child {
        button {
          @include radius(0 5px 5px 0);
        }
      }
    }
  }
}
// Alerts

.alert {
  @include font__size(14px);
  @include line__height(18px);

  &-info {
    @include radius(10px);
    padding: 1rem;
    @include font__size(11px);
    @include line__height(19px);
    font-weight: $font-weight--400;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &+li {
          margin-top: 0.375rem;
        }
      }

      &.list-ordered {
        list-style-type: lower-roman;
        list-style-position: inside;
      }
    }
  }
}

// Logos

.logo {
  .icon {
    width: 60px;
    height: 60px;
    background: $color-brand;
    @include radius(10px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;

    img {
      height: 36px;
      width: auto;
    }
  }

  &.logo {
    // GLOBAL

    &--activity {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #a1c7f5 10.57%,
            #2064c9 94.21%) !important;
      }
    }

    // DICE

    &--organization-configuration {

      .icon,
      .more {
        background: linear-gradient(230.19deg,
            #842029 3.79%,
            #5d171e 96.21%) !important;
      }
    }

    &--incident-configuration {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #20c9bf 10.57%,
            #1a99b3 94.21%) !important;
      }

      img {
        height: 32px;
      }
    }

    &--notification {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #e9cf46 10.57%,
            #b99f17 94.21%) !important;
      }
    }

    &--situation-intelligence {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #e8844c 10.57%,
            #b7551e 94.21%) !important;
      }
    }

    &--incident-action-planning {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #ef4a4a 10.57%,
            #af2828 94.21%) !important;
      }
    }

    &--report {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #7f7df8 10.57%,
            #363ccc 94.21%) !important;
      }
    }

    &--event-monitor {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #d73585 10.57%,
            #193889 94.21%) !important;
      }
    }

    &--mission-manager {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #41bfc7 10.57%,
            #1f848a 94.21%) !important;
      }
    }

    &--workflows {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #25ca99 10.57%,
            #187e60 94.21%) !important;
      }
    }

    &--decision-desk {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #1f848a 10.57%,
            #551a4c 94.21%) !important;
      }
    }

    &--task-management {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #DE5C9D 10.57%,
            #D63384 94.21%) !important;

        img {
          width: 36px;
        }
      }
    }

    &--subscription-management {

      .icon,
      .more {
        background: linear-gradient(232deg, #4AEFC7 0%, #186247 100%) !important;
      }
    }

    // PREPARE

    &--plan {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #4dd4ac 10.57%,
            #20c997 94.21%) !important;
      }
    }

    &--notebook {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #0a58ca 10.57%,
            #084298 94.21%) !important;
      }
    }

    &--observe {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #520dc2 10.57%,
            #3d0a91 94.21%) !important;

        img {
          height: 18px;
        }
      }
    }

    &--chat {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #dc3545 10.57%,
            #b02a37 94.21%) !important;

        img {
          height: 30px;
        }
      }
    }

    &--settings {

      .icon,
      .more {
        background: linear-gradient(230.19deg,
            #842029 3.79%,
            #5d171e 96.21%) !important;
      }
    }

    &--media {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #3dd5f3 10.57%,
            #0aa2c0 94.21%) !important;
      }
    }

    &--web {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #6c757d 10.57%,
            #4d545b 94.21%) !important;
      }
    }

    &--map {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #459e74 10.57%,
            #1f8a59 94.21%) !important;

        img {
          height: 26px;
        }
      }
    }

    &--resources {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #f9bc07 10.57%,
            #a57d05 94.21%) !important;
      }
    }

    &--learn {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #ea868f 10.57%,
            #dc3545 94.12%) !important;
      }
    }

    &--developer {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #583599 10.57%,
            #472b7b 94.21%) !important;

        img {
          height: 28px;
        }
      }

      &__users {

        .icon,
        .more {
          background: linear-gradient(232.13deg,
              #3dd5f3 10.57%,
              #0aa2c0 94.21%) !important;
        }
      }

      &__workspaces {

        .icon,
        .more {
          background: linear-gradient(232.13deg,
              #520dc2 10.57%,
              #3d0a91 94.21%) !important;
        }
      }
    }

    &--email {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #fd9843 10.57%,
            #fd7e14 94.21%) !important;

        img {
          height: 26px;
        }
      }
    }

    &--command {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #de5c9d 10.57%,
            #d63384 94.21%) !important;

        img {
          height: 21px;
        }
      }
    }

    &--report {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #8c6489 10.57%,
            #551a4c 94.21%) !important;

        img {
          height: 26px;
        }
      }
    }

    &--activity {

      .icon,
      .more {
        background: linear-gradient(232.13deg,
            #a1c7f5 10.57%,
            #2064c9 94.21%) !important;

        img {
          height: 26px;
        }
      }
    }
  }
}

// Status

.status {
  background: var(--background-primary);
  font-weight: 500;
  @include font__size(13px);
  @include line__height(34px);
  padding: 0 0.625rem;
  @include radius(5px);
  display: inline-block;
  min-width: 100px;
  text-align: center;

  &--sml {
    @include line__height(24px);
    min-width: auto;
  }

  &--xsml {
    @include font__size(12px);
    @include line__height(20px);
    font-weight: $font-weight--600;
    min-width: auto;
    padding: 0 0.5rem;
  }

  .form-label {
    @include font__size(13px);
    @include line__height(34px);
  }

  &--available {
    background: #1fbc9c;
  }

  &--archived {
    background: #fd7e14;
  }

  &--icon {
    width: 34px;
    height: 34px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    min-width: auto;

    svg {
      height: 1.25rem;
      width: auto;
    }

    &.sml {
      height: 1.25rem;
      width: 1.25rem;

      svg {
        height: 0.75rem;
      }
    }
  }

  &--bubble {
    min-width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0.2rem;
    @include font__size(10px);
    @include line__height(15px);
    @include radius(30px);
    font-weight: $font-weight--500;
    background: $color-brand;
    text-align: center;
  }
}

// Card
// Uploaded File

.cards {
  position: relative;

  .remove {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    cursor: pointer;
    @include transition($transition);

    &:hover {
      @include scale(1.1);
    }
  }

  &__uploaded-file {
    @include font__size(13px);
    @include line__height(20px);
    font-weight: $font-weight--400;
    color: $color-white;
    background: $gray-700;
    @include radius(10px);
    padding: 1.25rem;
    padding-top: 1.5rem;
    position: relative;

    .icon {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }

    .title {
      display: block;
      @include ellipsis;
      text-align: center;
    }
  }
}

// Icon Clickabkle

.icon-clickable {
  cursor: pointer;
}

// Dangerously Set Inner Html

.dangerously-loaded-inner-html {
  &>* {
    margin-bottom: 0.5rem;

    &:last-child {
      margin: 0;
    }

    ul {
      li {
        &:not(:first-child) {
          margin-top: 0.25rem;
        }
      }
    }
  }
}

// Signature

.sigCanvas {
  display: block !important;
  height: 75px !important;
  width: 100% !important;
  @include radius(10px);
}

// Sidebar

.site-sidebar {
  background: $gray-800;
  @include radius(10px);
  padding: 1rem;

  @include media-breakpoint-down(xl) {
    &.hide {
      display: none !important;
    }

    &.show {
      display: block !important;
    }
  }

  .section {
    border-bottom: dashed 2px $gray-700;
    padding: 1rem 0;

    &:last-of-type {
      border: none;
    }
  }

  h6 {
    margin: 0 0 0.5rem;
    text-transform: uppercase;

    &~h6 {
      margin-top: 0.75rem;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      &+li {
        margin-top: 0.125rem;
      }

      a {
        color: $color-white !important;
        @include transition($transition);
        display: flex;
        align-items: center;
        padding: 0.375rem 1rem;
        word-break: break-all;
        hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;

        &.active,
        &:hover {
          background: $gray-700;
        }

        &:before {
          @include fontawesome('\f105');
          margin-right: 0.5rem;
        }
      }
    }
  }
}

// Initials

.initials {
  width: 8rem;
  height: 8rem;
  display: flex;
  padding: 1rem;
  @include radius(5px);
  background: $color-brand;
  flex-shrink: 0;

  h1 {
    margin: auto;
    text-transform: uppercase;
    @include ellipsis;
    text-align: center;
  }

  @include media-breakpoint-down(md) {
    & {
      width: 4rem;
      height: 4rem;
      padding: 0;

      h1 {
        @include font__size(22px);
        @include line__height(30px);
      }
    }
  }
}

// Gallery Unsplash

.gallery {
  &--unsplash {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 399px;
    background: lighten($gray-900, 5%);
    padding: 1rem;
    @include radius(10px);

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-700;
      outline: none;
      @include radius(1rem);
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-700;
      outline: none;
      @include radius(1rem);
    }

    &>img {
      width: calc((100% - 4rem) / 5);
      height: auto;
      @include radius(5px);

      &:nth-child(5)~img {
        margin-top: 1rem;
      }

      &.selected {
        border: solid 0.25rem $color-white;
      }
    }
  }
}

/* Accordion shape customize */
.standard-accordion {

  .accordion-button {
    text-transform: unset;
  }

  .accordion-button:before {
    box-sizing: border-box;
    content: unset;
  }

  .accordion-button:after {
    content: "\f107";
    font-family: "FontAwesome";
    font-size: inherit;
    line-height: inherit;
    display: inline-block;
    vertical-align: top;
    flex-shrink: 0;
    font-size: 20px;
    margin-right: 0;
    margin-top: -1px;
    background-image: none;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: none;
  }

  .accordion-header {
    border-bottom: 1px solid #6C757D;
  }

  .accordion-item:last-child {
    .accordion-header {
      border-bottom: 0;
    }

    .accordion-body {
      border-bottom: 0;
    }

    .show {
      border-top: 1px solid #6C757D;
      overflow: hidden;
    }
  }

  .accordion-body {
    padding: 0 1rem;
    border-bottom: 1px solid #6C757D;
  }
}

