
.iap-section-title {
  @extend .txt--xsml;
  margin-bottom: -0.75rem;
}

.iap-section-grouping {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--border-neutral);
  padding: 0.75rem 0.75rem 1rem 1rem;
}
