/////////////////////////
// Range Slider
/////////////////////////

.rangeSlider {
  &-24hrs {
    .calendar {
      position: absolute;
      bottom: calc(100% + 0.5rem);
      left: 1.5rem;

      > div > .button--icon {
        // negative bottom margin positions the close button outside its container.
        margin: 0.25rem 0.25rem -2.5rem 0.25rem;
      }
    }
  }
}
