/////////////////////////
// Animation
/////////////////////////

@-webkit-keyframes loader {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-250%);
        transform: translateY(-250%);
    }

    40%,
    60% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(250%);
        transform: translateY(250%);
    }
}

@keyframes loader {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-250%);
        transform: translateY(-250%);
    }

    40%,
    60% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(250%);
        transform: translateY(250%);
    }
}