/////////////////////////
// Dropdown
/////////////////////////

.dropdown {
  .button {
    &--primary {
      i {
        font-size: 1.25rem;
      }

      span {
        padding: 0 0.75rem;
      }

      &:hover {
        background: darken($color-brand, 5%);
        color: var(--background-alt);
      }
    }

    &--icon {
      &:hover {
        border-color: var(--brand-primary);
        background: none;
      }
    }
  }

  &-toggle {
    &:after {
      display: none;
    }

    &.show {
      background: var(--brand-primary);
      border-color: var(--brand-primary);
    }
  }

  &-menu {
    padding: 0.25rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.5rem;
    border: 1px solid var(--border-neutral);
    background: var(--background-inverse);

    box-shadow: 0px 4px 6px -2px rgba(34, 34, 34, 0.03),
      0px 12px 16px -4px rgba(34, 34, 34, 0.08);

    h6 {
      padding: 0.375rem 0.5rem;
      margin: 0.25rem 0;
      background: var(--neutral-900);
      font-weight: 600;
    }
  }

  &-item {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: var(--text-white) !important;
    display: flex;
    align-items: center;
    @include transition($transition);
    padding: 0.625rem 0.75rem;

    img {
      height: 14px;
      width: auto;
      margin-right: 0.5rem;
    }
    .material-symbols-outlined {
      font-size: 1.25rem;
      padding-right: 0.5rem;
    }

    &:active,
    &:focus {
      background: none;
      color: var(--background-alt) !important;
    }

    &.active,
    &:hover {
      background: var(--background-light);
      color: var(--background-alt) !important;
    }

    &.disabled {
      opacity: 0.25;
    }
  }
}

body.user-is-tabbing .dropdown-item {
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 4px var(--border-primary-focus);
    border-radius: 0.25rem;
  }
}

// Styles used once
.dropdown__pratus {
  border: none !important;

  .dropdown-menu {
    background-color: var(--neutral-600) !important;
    border: none !important;
    border-radius: 0.25rem;
    padding: 0rem 1rem;
    width: 350px;

    .dropdown-divider {
      border: 1px solid var(--border-primary);
      margin-left: -1rem;
      margin-right: -1rem;
    }

    .section {
      &-profile {
        padding-top: 0.75rem;

        @include media-breakpoint-down(lg) {
          padding-top: 0.25rem;
        }
        .avatar-initials {
          border-radius: 2rem;
        }
      }

      &-select {
        padding-bottom: 0.8rem;

        @include media-breakpoint-down(lg) {
          padding-bottom: 0rem;
        }

        .form-label {
          margin-top: 1rem;

          @include media-breakpoint-down(lg) {
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;
          }
        }

        .StylishNewSelect:hover {
          border-color: var(--border-primary-focus);
        }
      }

      &-pages {
        .dropdown-item {
          line-height: 1.25rem;
          padding: 0.62rem 1rem;
          margin: 0rem -1rem;
          width: calc(100% + 2rem);

          @include media-breakpoint-down(lg) {
            padding: 0.1rem 1rem;
          }
        }
      }
    }

    .version {
      color: var(--text-weak);
      font-size: 0.625rem;
      line-height: 1.125rem;
      display: flex;
      justify-content: center;
    }
  }

  .badge {
    border: none;
    background-color: var(--background-primary);
    width: 90%;
    padding: 0.625rem 0.375rem;
    justify-content: center;
    font-weight: 600;
  }
}

body.user-is-tabbing .dropdown__pratus .dropdown-toggle:focus {
  outline: none;
  box-shadow: inset 0 0 0 4px var(--border-primary-focus);
}

.dropdown__navigation {
  // used once in the old AppHeader
  & + .dropdown__navigation {
    margin-left: 1.5rem;
  }

  .dropdown {
    &-toggle {
      background: none;
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      font-size: 0.875rem;
      @include line__height(22px);
      font-weight: 600;
      color: var(--background-alt);
      display: flex;
      box-shadow: none !important;
      width: auto;
      height: auto;

      &:before {
        @include fontawesome('\f107');
        margin-left: 0.5rem;
        order: 1;
        position: relative;
        @include transition($transition);
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
        box-shadow: none;
      }
    }

    &-menu {
      min-width: 200px;
    }
  }

  &.show {
    .dropdown {
      &-toggle {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.dropdown__movefolder {
  padding: 0.625rem 0.75rem;

  & .last {
    & .dropdown {
      &-toggle {
        &::before {
          display: none;
        }

        svg {
          margin-left: 0;
        }
      }
    }

    &.show {
      &.dropdown {
        padding: 0 0 0 3.2rem !important;
      }
    }
  }

  & .last-parent {
    padding: 0 0 0 2.5rem !important;

    & .dropdown {
      &-toggle {
        &::before {
          display: none;
        }

        svg {
          margin-left: 1.9rem;
        }
      }
    }
  }

  .dropdown {
    &-toggle {
      border: none;
      width: 100%;
      justify-content: flex-start;

      &:before {
        @include fontawesome('\f107');
        @include transition($transition);
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
        box-shadow: none;
      }

      svg {
        height: 1.25rem;
        margin: 0 1rem;

        .opacity {
          filter: brightness(0.75);
        }
      }

      h6 {
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
    }
  }

  &.show {
    .dropdown {
      //TODO: children files overlap in the same position. need to separate
      border-bottom: 0;
      padding: 0 1.5rem;
      @include media-breakpoint-down(sm) {
        padding: 0 0.5rem;
      }

      &-menu {
        padding-bottom: 0;
      }
    }

    & > .dropdown-toggle {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown-colorPicker {
  .dropdown {
    &-menu {
      width: 500px;
      background: var(--neutral-800);
      padding: 1.25rem;
      box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);

      @include media-breakpoint-down(md) {
        & {
          width: calc(100vw - 2rem);
          inset: auto -16px 8px auto !important;
        }
      }
    }

    &-toggle {
      position: relative;
      background: var(--background-alt);
      overflow: hidden;

      img {
        height: 1.5rem;
      }

      span {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &:hover {
        border-color: var(--background-light);
        background: var(--background-alt);
      }
    }
  }
}

.dropdown-item__upload {
  input[type='file'] {
    position: absolute;
    opacity: 0;
  }
}
