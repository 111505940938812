/////////////////////////
// Notebook Module
/////////////////////////

.site-main__notebook {
  .form-block {
    &--notebook {
      .item {
        width: calc((100% - 10rem) / 6);
        margin-right: 2rem;
        display: flex;
        align-items: flex-start;

        &:nth-child(6n) {
          margin-right: 0;
        }

        @include media-breakpoint-down(xl) {
          & {
            width: calc((100% - 8rem) / 5);

            &:nth-child(6n) {
              margin-right: 2rem;
            }

            &:nth-child(5n) {
              margin-right: 0;
            }
          }
        }

        @include media-breakpoint-down(lg) {
          & {
            width: calc((100% - 6rem) / 4);

            &:nth-child(6n) {
              margin-right: 2rem;
            }

            &:nth-child(5n) {
              margin-right: 2rem;
            }

            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }

        @include media-breakpoint-down(md) {
          & {
            width: calc((100% - 2rem) / 2);

            &:nth-child(5n) {
              margin-right: 2rem;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }

        &__top {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          cursor: pointer;
        }

        &__pic {
          width: 100%;
          height: 100%;
          display: block;
        }

        &__icon {
          width: 25%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          @include translate(-50%, -50%);

          svg {
            width: 100%;
            height: auto;
          }
        }

        &__opacity {
          filter: brightness(0.75);
        }

        h6 {
          @include media-breakpoint-down(sm) {
            font-size: 0.7rem;
            line-height: 1rem;
          }
        }
      }
    }

    &--folder {
      .item {
        width: calc((100% - 15rem) / 6);
        margin-right: 3rem;
        display: flex;
        align-items: flex-start;

        &:nth-child(6n) {
          margin-right: 0;
        }

        @include media-breakpoint-down(xl) {
          & {
            width: calc((100% - 12rem) / 5);

            &:nth-child(6n) {
              margin-right: 3rem;
            }

            &:nth-child(5n) {
              margin-right: 0;
            }
          }
        }

        @include media-breakpoint-down(lg) {
          & {
            width: calc((100% - 6rem) / 3);

            &:nth-child(6n) {
              margin-right: 3rem;
            }

            &:nth-child(5n) {
              margin-right: 3rem;
            }

            &:nth-child(3n) {
              margin-right: 0rem;
            }
          }
        }

        @include media-breakpoint-down(md) {
          & {
            width: calc((100% - 3rem) / 2);

            &:nth-child(6n) {
              margin-right: 3rem;
            }

            &:nth-child(5n) {
              margin-right: 3rem;
            }

            &:nth-child(3n) {
              margin-right: 3rem;
            }

            &:nth-child(2n) {
              margin-right: 0rem;
            }
          }
        }

        &__pic {
          width: 100%;
          height: auto;
          display: block;
          cursor: pointer;
        }

        &__opacity {
          filter: brightness(0.75);
        }

        h6 {
          @include media-breakpoint-down(sm) {
            font-size: 0.7rem;
            line-height: 1rem;
          }
        }
      }
    }

    &--files {
      .item {
        width: calc((100% - 15rem) / 6);
        margin-right: 3rem;
        display: flex;
        align-items: flex-start;

        &:nth-child(6n) {
          margin-right: 0;
        }

        @include media-breakpoint-down(xl) {
          & {
            width: calc((100% - 9rem) / 4);

            &:nth-child(6n) {
              margin-right: 3rem;
            }

            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }

        @include media-breakpoint-down(lg) {
          & {
            width: calc((100% - 6rem) / 3);

            &:nth-child(6n) {
              margin-right: 3rem;
            }

            &:nth-child(4n) {
              margin-right: 3rem;
            }

            &:nth-child(3n) {
              margin-right: 0rem;
            }
          }
        }

        @include media-breakpoint-down(md) {
          & {
            width: calc((100% - 1rem) / 2);
            margin-right: 1rem;

            &:nth-child(6n) {
              margin-right: 1rem;
            }

            &:nth-child(4n) {
              margin-right: 1rem;
            }

            &:nth-child(3n) {
              margin-right: 1rem;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }

        &__uploaded--file {
          padding: 0 0 0.5rem;
          overflow: hidden;
          min-height: 100%;
        }

        &__icon--file {
          margin-bottom: 0.5rem;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          height: 90px;
          align-items: center;

          img {
            height: 45px;
          }
        }

        &__tittle {
          @include ellipsis();
          padding: 0 0.5rem;
        }
      }

      .form-search {
        width: 100%;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }

  // .button {
  //   &--icon {
  //     width: auto;
  //     height: auto;
  //     padding: 0;
  //   }
  // }
  .ql-container {
    height: calc(100vh - 32rem);
  }
}