
/* Global color Vars */
/* Some of these could also be generated in by bootstrap
 *  by setting the $colors, $grays, and $theme-colors maps
 */

:root {
  --color-black: #000;
  --color-paleturquoise-100: #b8efd8;
  $color-royalblue: #6876ee;
  --color-royalblue: #{$color-royalblue};
  --color-slategray-100: #6c7390;
  --error-100: #fee4e2;
  --error-200: #ffcdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #da2d20;
  --error-700: #b42318;
  --error-800: #912018;
  --error-900: #7a271a;
  --info-100: #e3f6f6;
  --info-200: #c7eeee;
  --info-300: #aae5e5;
  --info-400: #8edddd;
  --info-500: #72d4d4;
  --info-600: #5dacac;
  --info-700: #478585;
  --info-800: #325d5d;
  --info-900: #1d3535;
  --neutral-100: #f8f9fa;
  --neutral-200: #dfe2e6;
  --neutral-300: #cfd4da;
  --neutral-400: #afb5bc;
  --neutral-500: #6e757c;
  --neutral-600: #4b5057;
  --neutral-700: #353A40;
  --neutral-800: #222529;
  --neutral-900: #14171A;
  --neutral-1000: #090909;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d584;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;
  --tech-blue-100: #d9f1ff;
  --tech-blue-200: #a3deff;
  --tech-blue-300: #6ecafe;
  --tech-blue-400: #38b7fe;
  --tech-blue-500: #02a3fe;
  --tech-blue-600: #0184cf;
  --tech-blue-700: #01669f;
  --tech-blue-800: #01476f;
  --tech-blue-900: #012940;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b64708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;
  --organizations-aqua: #95e9e4;
  --organizations-carrot: #ef8c6e;
  --organizations-cherry: #f26969;
  --organizations-tangerine: #f3bd66;
  --organizations-fossil: #6c6e70;
  --organizations-fuchsia: #f46fb3;
  --organizations-lavender: #d094ed;
  --organizations-lemon: #f9df6b;
  --organizations-ocean: #4273d5;
  --organizations-orchid: #a69ff5;
  --organizations-pine: #7ccba7;
  --organizations-blush: #faaaf0;
  --organizations-salmon: #fc979a;
  --organizations-sea: #49cbc4;
  --organizations-pear: #afe08e;

  // brand colors
  --brand-primary: var(--tech-blue-500);
  --brand-secondary: var(--color-royalblue);
  $brand-secondary-dark5: darken($color-royalblue, 5%);

  --background-light: var(--neutral-700);
  --background-dark: var(--neutral-800);
  --background-inverse: var(--neutral-900);
  --background-primary-dark: var(--neutral-1000);
  --background-primary: var(--tech-blue-700);
  --background-primary-active: var(--tech-blue-900);
  --background-primary-hover: var(--tech-blue-600);
  --background-primary-focus: var(--tech-blue-500);
  --background-primary-disabled: #C6C6C6;
  --background-secondary: var(--brand-secondary);
  --background-secondary-active: #{$brand-secondary-dark5};
  --background-alt: #FFFFFF;
  --background-alt-disabled: #DDDDDD;
  --background-alt-hover: var(--neutral-100);
  --background-overlay: rgba(0, 0, 0, 0.75);
  --background-brand-gradient: linear-gradient(90deg, var(--tech-blue-500) 0%, var(--color-royalblue) 100%);

  --text-dark: var(--neutral-900);
  --text-disabled: var(--neutral-500);
  --text-weak: var(--neutral-400);
  --text-white: var(--neutral-100);
  --text-primary: var(--tech-blue-500);
  --text-primary-hover: var(--tech-blue-400);
  --text-error: var(--error-400);

  --border-neutral: var(--neutral-700);
  --border-neutral-disabled: var(--neutral-700);
  --border-primary: var(--neutral-500);
  --border-primary-active: var(--tech-blue-800);
  --border-primary-focus: var(--tech-blue-500);
  --border-primary-disabled: var(--neutral-700);
  --border-error: var(--error-400);


  // Spacing variables
  --border-radius: 0.25rem;
}