/////////////////////////
// Settings Module
/////////////////////////

.site-main {
    &__settings {
        .box {
            background: $gray-700;
            @include radius(10px);
            padding: 1.5rem;
        }

        .settings {
            &__application {
                .block {
                    p {
                        height: auto;
                        overflow: visible;
                    }
                }
            }

            &__user-settings {
                .separator--left {
                    border-left: dashed 2px $gray-700;

                    @include media-breakpoint-down(md) {
                        & {
                            border: none;
                        }
                    }
                }

                .button-group {
                    border-top: solid 2px $gray-700;
                }

                p {
                    white-space: normal;
                    word-wrap: break-word;
                    word-break: break-all;
                }
            }

            &__inject-methods {
                .table {
                    .status {
                        height: 34px;
                        width: 34px;
                        min-width: auto;
                        padding: 0;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        vertical-align: top;

                        svg {
                            height: 1.25rem;
                            max-width: 30px;
                            width: auto;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}