.icon-container-with-button {
    border: 1px solid var(--neutral-700);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    max-width: 26rem;

    .icon-text-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 1rem 1.5rem;
        
        .icon {
            i {
                font-size: 4rem !important;
            }
            width: 4rem;
            height: 4rem;
        }

        .title {
            font-size: 18px;
            line-height: 1.75rem;
            font-weight: 600;
        }

        .description {
            font-size: 12px;
            text-align: center;
        }
    }

    .button-container {
        border-top: 1px solid var(--neutral-700);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px auto;

        .button {
            background-color: inherit;
            border: none;
            color: var(--tech-blue-500);
            font-weight: 600;
            font-size: 14px;
        }
    }
}

.icon-container-with-button:hover {
    cursor: pointer;
}