.avatar-initials {
  background: var(--neutral-600);
  border: 1px solid var(--neutral-600);
  border-radius: var(--border-radius);
  color: var(--organizations-tangerine);
  text-align: center;
  font-weight: 700;
  align-content: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;

  &.sml {
    width: 40px;
    height: 40px;
  }
}

.dropdown-toggle .avatar-initials {
  &:hover {
    color: var(--brand-primary)
  }
}

// Avatar (social media)
.avatar {
  background: $gray-700;
  border: solid 2px $gray-600;
  width: 4rem;
  height: 4rem;
  @include radius(.25rem);
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: "";
    background: url(/assets/images/icon__user.svg) no-repeat 0 0;
    background-size: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    @include translate(-50%, -50%);
    width: 20px;
    height: 24px;
    z-index: -1;
  }

  img {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
  }

  &.circle {
    @include radius(50%);
  }

  &.large {
    width: 8rem;
    height: 8rem;

    &:after {
      width: 42px;
      height: 50px;
    }
  }

  &.mid {
    width: 5.625rem;
    height: 5.625rem;

    &::before {
      background: url(/assets/images/icon__image.svg) no-repeat 0 0;
      width: 3rem;
      height: 3rem;
      opacity: .25;
    }
  }

  &.xlarge {
    width: 200px;
    height: 200px;

    &::before {
      background: url(/assets/images/icon__image.svg) no-repeat 0 0;
      width: 80px;
      height: 80px;
      opacity: .25;
    }
  }
}