.analysis-content {
  border: 1px solid var(--border-neutral);
  border-radius: 0.25rem;
}

.Sitrep {
  padding-bottom: 128px !important;
}

.SitrepSection {
  background-color: var(--background-light);
  border-radius: 0.25rem;
}

.SitrepSection-editor-wrap {
  height: 100%;
}

.paragraph-header {
  margin: 8px 0 8px 0;
  display: flex;
  padding: 0 0.75rem;
  border-bottom: 1px solid var(--neutral-500);
  justify-content: space-between;
  align-items: center;

  h4 {
    color: var(--text-weak);
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-transform: uppercase;
  }
}

.generate-button {
  background-color: var(--color-royalblue);
  color: var(--text-white);
  width: 100%;
}

.small-title {
  font-size: 0.75rem;
  padding: 0.75rem 0;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 700;
  line-height: 1.125rem;
}

.big-title {
  font-size: 1.25rem;
  text-align: center;
  line-height: 1.875rem;
}

.tab-footer {
  margin: 16px 0 16px 0;
  width: 600px;
  display: flex;
  align-items: center;
  background-color: var(--background-light);
  position: fixed;
  right: 47px;
  bottom: -20px;
  padding: 1rem;
  justify-content: space-between;
  border-top: 1px solid var(--border-primary);
}

.text-no-hover:focus {
  background-color: var(--background-inverse);
}

.date-section {
  padding-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.add-section {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px dotted var(--neutral-500);
  background-color: var(--background-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}
