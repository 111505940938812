/////////////////////////
// Train Module
/////////////////////////

.train {
  &__msel-timeline {
    &>.row {
      position: relative;
      display: flex;
      align-items: flex-start;

      .row {

        &-info,
        &-cards {
          padding: 0 1rem;
        }

        &-info {
          width: 330px;
        }

        &-cards {
          width: calc(100% - 330px);
        }

        @include media-breakpoint-down(xl) {
          &-info {
            width: 240px;
          }

          &-cards {
            width: calc(100% - 240px);
          }
        }

        @include media-breakpoint-down(lg) {
          &-info {
            width: 200px;
          }

          &-cards {
            width: calc(100% - 200px);
          }
        }

        @include media-breakpoint-down(md) {
          &-info {
            width: 100%;
          }

          &-cards {
            width: 100%;
          }
        }
      }

      &:before {
        content: "";
        width: 1px;
        height: calc(100% + 2rem); // Included row gap
        background: $gray-600;
        position: absolute;
        // right: calc(120px + 1.5rem + 10px); // Status + Round right gap & half width
        left: 159px;
        top: 0;
        z-index: -1;
      }

      @include media-breakpoint-down(xl) {
        & {
          &:before {
            right: auto;
            left: 26px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        & {
          &:before {
            right: auto;
            left: 24px;
          }
        }
      }

      &:not(:first-child) {
        margin-top: 2rem;
      }

      &:last-child {
        &:before {
          // display: none;
          height: 100%;
        }

        .info {
          //   &:before {
          //     display: none;
          //   }
        }
      }

      @include media-breakpoint-down(md) {
        & {
          padding-left: 3rem;
          position: relative;

          &>[class*="col-"] {
            position: static;
          }
        }
      }
    }

    .info {
      // &:before {
      //     content: "";
      //     width: 1px;
      //     height: calc(100% + 2rem); // Included row gap
      //     background: $gray-600;
      //     position: absolute;
      //     // right: calc(120px + 1.5rem + 10px); // Status + Round right gap & half width
      //     left: 12%;
      //     top: 0;
      //     z-index: -1;
      // }

      // @include media-breakpoint-down(xl) {
      //     & {
      //         &:before {
      //             right: auto;
      //             left: 10px;
      //         }
      //     }
      // }

      @include media-breakpoint-down(lg) {
        & {
          text-align: right;
        }
      }

      @include media-breakpoint-down(md) {
        & {
          position: static;

          &:before {
            left: calc((3rem - 1px) / 2);
          }
        }
      }
    }

    .card {
      padding: 1rem 1.25rem;
      display: flex;
      box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.2);
      font-weight: $font-weight--500;
      position: relative;
      cursor: pointer;
      min-height: 100%;
      border-radius: 0.25rem;
      border: none;
      margin: 0;
      flex-direction: row;

      @include media-breakpoint-down(lg) {
        & {
          flex-direction: column;
        }
      }

      @include media-breakpoint-down(md) {
        & {
          @include radius(5px);
        }
      }

      &>div {
        &:not(:first-child) {
          margin-left: auto;
          padding-left: 1rem;
          text-align: right;

          @include media-breakpoint-down(lg) {
            & {
              margin: 0;
              padding-left: 0;
              text-align: left;
            }
          }
        }
      }

      i {
        position: absolute;
        left: calc(100% + (1.5rem - 5px));
        top: 50%;
        @include translate(0, -50%);
        color: $color-white;
        @include font__size(28px);

        @include media-breakpoint-down(md) {
          top: calc(100% + -1px);
          left: 50%;
          transform: translate(-50%, 0) rotate(90deg);
        }
      }
    }

    .round {
      height: 21px;
      width: 21px;
      @include radius(50%);
      border-style: solid;
      border-width: 5px;
      background-color: $color-white;
      margin: 0 1.5rem;
      flex-shrink: 0;

      @include media-breakpoint-down(xl) {
        & {
          position: absolute;
          left: 0;
          top: 0;
          margin: 0;
          display: block;
        }
      }

      @include media-breakpoint-down(md) {
        & {
          left: calc((3rem - 21px) / 2);
        }
      }
    }

    .status:not(.status--sml):not(.status--xsml) {
      min-width: 120px;

      @include media-breakpoint-down(xl) {
        & {
          width: 100%;
          min-width: auto;
        }
      }

      @include media-breakpoint-down(lg) {
        & {
          width: auto;
        }
      }

      @include media-breakpoint-down(md) {
        & {
          min-width: 150px;
        }
      }
    }

    .date {
      font-weight: $font-weight--600;
      @include line__height(20px);

      @include media-breakpoint-down(xl) {
        & {
          display: block;
          text-align: right;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}