.badge {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: var(--text-white);
  border-radius: 3.125rem;
  border: 1px solid var(--border-neutral);
  padding: 0.5rem 0.75rem;
  display: inline-flex;
  align-items: center;
  height: fit-content;

  img,
  svg {
    margin-right: 1rem;
    height: 1.5rem;
    width: auto;
  }

  button {
    // hidden button style, used for removal in most cases.
    border: none;
    background: inherit;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin-left: 0.75rem;

    i {
      color: var(--text-white);
      vertical-align: middle;
      font-size: 0.75rem;
    }

    &:hover i {
        color: var(--text-error)
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px var(--border-primary-focus);
      border-radius: 0.25rem;
    }
  }
}

.badge--with-remove {
  padding: 0;
  overflow: hidden;

  .icon {
    padding: 0.75rem 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  button {
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    padding: 0;
    width: 3rem;
    height: 3rem;
    border-left: solid 1px $gray-600;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition($transition);

    &.disabled {
      user-select: none;
      cursor: not-allowed;

      img {
        opacity: 0.5;
      }

      &:hover {
        background: $gray-700;
      }
    }

    &:hover {
      background: $gray-600;
    }

    img {
      margin: 0;
      height: 1rem;
    }
  }
}

.badge {
  &.badge--sm {
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 700;

  }
  &.badge--md {
    padding: 0.375rem 0.875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;

  }
  &.badge--lg {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }

  span:not(:first-child):not(:last-child) {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }

  &.badge--neutral {
    background-color: var(--background-light);
  }
  &.badge--error {
    background-color: var(--error-500);
  }
  &.badge--success {
    background-color: var(--success-600);
  }
}
