/////////////////////////
// Help
/////////////////////////

.help {
  .dropdown {
    &-toggle {
      background: none;
      border: solid 2px var(--neutral-600);
      @include radius(10px);
      outline: none;
      box-shadow: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;

      img {
        height: 1.25rem;
        width: auto;
      }

      &:after {
        display: none;
      }

      &:hover {
        border-color: var(--border-primary-focus);
        background: none;
      }
    }

    &-menu {
      .help-title {
        color: var(--text-white);
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
      }

      color: var(--text-weak);
      border: 1px solid var(--text-weak);
      border-radius: 0.25rem;
      padding: 0.75rem;
      box-shadow: 0px 4px 6px -2px rgba(34, 34, 34, 0.03),
        0px 12px 16px -4px rgba(34, 34, 34, 0.08);
      width: 454px;
      font-size: 0.75rem;
      line-height: 1.125rem;

      p {
        &:last-child {
          margin: 0;
        }
      }

      @include media-breakpoint-down(md) {
        & {
          width: 100vw;
          inset: 0px 0px auto auto !important;
        }
      }
    }
  }

  &.show {
    .dropdown {
      &-toggle {
        border-color: var(--border-primary-focus);

        &.button--icon {
          border-color: var(--border-primary-focus);
        }
      }
    }
  }

  &.sml {
    .dropdown {
      &-toggle {
        width: 34px;
        height: 34px;
        @include radius(5px);

        i {
          color: var(--text-white);
        }
        img {
          height: 1rem;
        }
      }
    }
  }
}
