/////////////////////////
// Reset
/////////////////////////

* {
  outline: none !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.weight-100 {
  font-weight: $font-weight--100 !important;
}

.weight-200 {
  font-weight: $font-weight--200 !important;
}

.weight-300 {
  font-weight: $font-weight--300 !important;
}

.weight-400 {
  font-weight: $font-weight--400 !important;
}

.weight-500 {
  font-weight: $font-weight--500 !important;
}

.weight-600 {
  font-weight: $font-weight--600 !important;
}

.weight-700 {
  font-weight: $font-weight--700 !important;
}

.weight-800 {
  font-weight: $font-weight--800 !important;
}

.weight-900 {
  font-weight: $font-weight--900 !important;
}

.color-brand {
  color: $color-brand !important;
}

.color-muted {
  color: $gray-600 !important;
}

.color-gray-900 {
  color: $gray-900 !important;
}

.color-danger {
  color: lighten($danger, 25) !important;
}

.color-black {
  color: $color-black !important;
}

.w-auto {
  width: auto !important;
}

.w-min-auto {
  min-width: auto !important;
}

.h-min-auto {
  min-height: auto !important;
}

.w-lg-auto {
  @include media-breakpoint-up(lg) {
    & {
      width: auto !important;
    }
  }
}

.w-md-auto {
  @include media-breakpoint-up(md) {
    & {
      width: auto !important;
    }
  }
}

.w-md-max-auto {
  @include media-breakpoint-down(md) {
    & {
      width: auto !important;
    }
  }
}

.h-md-100 {
  @include media-breakpoint-up(md) {
    & {
      height: 100% !important;
    }
  }
}

.h-lg-100 {
  @include media-breakpoint-up(lg) {
    & {
      height: 100% !important;
    }
  }
}

.position-md-relative {
  @include media-breakpoint-up(md) {
    & {
      position: relative !important;
    }
  }
}

.w-md-25 {
  @include media-breakpoint-up(md) {
    & {
      width: 25% !important;
    }
  }
}

.bg-0 {
  background: none !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.mb-05 {
  margin-bottom: 0.125rem !important;
}

.mt-05 {
  margin-top: 0.125rem !important;
}

.bg-brand {
  background: $color-brand !important;
}

.bg-gray-600 {
  background: $gray-600 !important;
}

.bg-gray-700 {
  background: $gray-700 !important;
}

.bg-gray-800 {
  background: $gray-800 !important;
}

.bg-gray-900 {
  background: $gray-900 !important;
}

.bg-gray-900 {
  background: $gray-900 !important;

  &--light-5 {
    background: lighten($gray-900, 5%) !important;
  }
}

.border-700 {
  border: solid 1px $gray-700;

  &.dashed {
    border-style: dashed;
  }

  &.wide-2px {
    border-width: 2px;
  }
}

.zIndex {
  &--1 {
    z-index: 1 !important;
  }
  &--2 {
    z-index: 2 !important;
  }
  &--3 {
    z-index: 3 !important;
  }
}

.separator {
  &--x {
    height: 2px;
    width: 100%;
    border-top: dashed 2px $gray-700;
  }

  &--y {
    height: 100%;
    width: 2px;
    background: $gray-700;

    &.h--16 {
      height: 1rem;
    }

    &.separator {
      &--800 {
        background: $gray-800;
      }
    }
  }
}

.break-word {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  hyphens: auto !important;
}

.img-h-12 {
  height: 0.75rem !important;
  width: auto !important;
}

.img-h-16 {
  height: 1rem !important;
  width: auto !important;
}

.has-img-h-16 {
  img,
  svg {
    height: 1rem !important;
    width: auto !important;
    display: block;
  }
}

.img-h-20 {
  height: 1.25rem !important;
  width: auto !important;
}

.has-img-h-20 {
  img,
  svg {
    height: 1.25rem !important;
    width: auto !important;
    display: block;
  }
}

.img-h-24 {
  height: 1.5rem !important;
  width: auto !important;
}

.has-img-h-24 {
  img,
  svg {
    height: 1.5rem !important;
    width: auto !important;
    display: block;
  }
}

.img-h-32 {
  height: 2rem !important;
  width: auto !important;
}

.has-img-h-32 {
  img,
  svg {
    height: 2rem !important;
    width: auto !important;
    display: block;
  }
}

.has-img-h-40 {
  img,
  svg {
    height: 2.5rem !important;
    width: auto !important;
    display: block;
  }
}

.img-h-48 {
  height: 3rem !important;
  width: auto !important;
}

.has-img-h-48 {
  img,
  svg {
    height: 3rem !important;
    width: auto !important;
    display: block;
  }
}

.img-h-128 {
  height: 8rem !important;
  width: auto !important;
}

.has-img-h-128 {
  img,
  svg {
    height: 8rem !important;
    width: auto !important;
    display: block;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.wrap {
  &-img-h-32 {
    img,
    svg {
      height: 2rem !important;
      width: auto !important;
    }
  }

  &-img-h-48 {
    img,
    svg {
      height: 3rem !important;
      width: auto !important;
    }
  }
}

.icon-group {
  img,
  svg {
    height: 1.2rem;
    width: auto;
    margin-left: 0.8rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.has-child {
  &-p {
    &--m-0 {
      p {
        margin: 0 !important;
      }
    }
  }
}
