/////////////////////////
// Select Box
/////////////////////////

.form-selectbox {
  .react-select {
    &__control {
      display: flex;
      padding: 0.625rem 0.75rem;
      align-items: center;
      gap: 0.5rem;
      align-self: stretch;
      border-radius: 0.25rem;
      border: 1px solid var(--border-neutral);
      background-color: inherit;
      box-shadow: 0px 1px 2px 0px rgba(16, 40, 34, 0.05);

      &:hover {
        border-color: var(--border-primary-active);
      }

      &:has(input:focus) {
        box-shadow: 0px 0px 4px 5px rgba(108, 117, 125, 0.15);
        border-color: var(--border-primary-active);
        background-color: inherit;
      }

      &--is-disabled {
        background: var(--neutral-600);
        border-color: var(--neutral-600);
        color: var(--text-white);
      }
    }

    &__custom-label {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &__single-value {
      margin: 0;
      font-weight: 400;
      color: var(--text-white);
      font-size: 1rem;
      font-style: normal;
      line-height: 1.25rem;

      .react-select__custom-label {
        display: none;
      }

      & + input {
        line-height: 20px;
      }
    }

    &__input-container {
      padding: 0;
      margin: 0;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: var(--text-white);
    }

    &__placeholder {
      padding: 0;
      margin: 0;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: var(--text-white);
      opacity: 0.75;
    }

    &__indicator {
      padding: 0;
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__indicator-separator {
      display: none;
    }

    &__clear-indicator {
      position: relative;

      svg {
        display: none;
      }

      &:after {
        content: '';
        background: url('/assets/images/icon__times.svg') no-repeat 0 0;
        background-size: 0.75rem;
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    &__dropdown-indicator {
      position: relative;
      color: var(--text-white);

      svg {
        display: none;
      }

      i {
        @include transition($transition);
      }

      &:after {
        @include materialsymbol('\e5c5');
        @include font__size(22px);
        @include transition($transition);
      }

      &:hover {
        color: var(--text-white);
      }
    }

    &__control--menu-is-open {
      .react-select__dropdown-indicator {
        &:after,
        i {
          transform: rotate(180deg);
        }
      }
    }

    &__menu {
      z-index: 4;
      margin: 0.5rem 0 0;
      overflow: hidden;
      border-radius: 0.25rem;
      border: 1px solid var(--border-neutral);
      background: var(--background-inverse);
      box-shadow: 0px 4px 6px -2px rgba(34, 34, 34, 0.03),
        0px 12px 16px -4px rgba(34, 34, 34, 0.08);
    }

    &__menu-list {
      padding-bottom: 0;
      padding-top: 0;
      max-height: 286px;

      &::-webkit-scrollbar {
        width: 0.25rem;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--neutral-500);
        outline: none;
        @include radius(1rem);
      }
    }

    &__option {
      font-weight: 400;
      @include transition($transition);
      padding: 0.625rem 0.75rem;
      display: flex;

      &--is-selected {
        background: var(--background-dark);
      }

      &--is-focused {
        background: var(--background-light);
      }
    }

    &__multi-value {
      background: var(--neutral-500);
      margin: 0 2px 0 0;
      display: flex;
      //padding: 0.25rem 0.5rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 3.125rem;
      background: var(--background-dark);

      &__label {
        font-weight: 500;
        padding: 0 8px;
        color: var(--text-primary);
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        line-height: 1.25rem;
      }

      &__remove {
        padding: 0 2px;
      }
    }
  }


  &.secondary {
    .react-select {
      &__control {
        background: var(--neutral-600);
        border-color: var(--border-primary);

        &:hover,
        &:focus {
          border-color: var(--neutral-400);
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        background: var(--background-dark);
        box-shadow: 0px 4px 10px 0 rgba(173, 173, 173, 0.2);
      }

      &__option {
        color: var(--text-white);
      }
    }
  }

  &.sml {
    .react-select {
      &__control {
        padding: 0.375rem 0.5rem;
      }

      &__single-value {
        font-size: 0.875rem;
      }

      &__option {
        font-size: 0.875rem;
        padding: 0.375rem 0.5rem;
      }
    }
  }

  &.light {
    .react-select {
      &__control {
        background-color: var(--neutral-300);
      }

      &__single-value {
        color: var(--text-black);
      }

      &__dropdown-indicator {
        color: var(--neutral-500);
      }

      &__menu {
        background: var(--neutral-100);
      }

      &__option {
        color: var(--text-black);

        &--is-selected {
          background: var(--neutral-200);
        }

        &--is-focused {
          background: var(--neutral-200);
        }
      }

      &__multi-value {
        background: var(--neutral-300);
      }
    }
  }

  // &.platform is unused. I could not find any place that passes "platform" into the
  // "classes" prop of StylishNewSelect (the only place we use .form-selectbox).
  &.platform {
    .react-select {
      &__control {
        background: none;
        border: none;
        @include radius(0px);
        box-shadow: none;

        &:focus {
          box-shadow: none;
          border-color: var(--neutral-600);
          color: var(--text-white);
          background: var(--neutral-700);
        }
      }

      &__value-container {
        padding: 0;
        padding-right: 0.75rem;
      }

      &__indicator-separator {
        display: none;
      }

      &__dropdown-indicator {
        width: auto;
      }

      &__single-value {
        @include font__size(16px);
        font-weight: 700;
      }

      &__menu {
        width: 9rem;
      }

      &__option {
        font-weight: 500;
        padding: 0.5rem 0.75rem;
      }
    }
  }
}

.time-select {
  .react-select {
    &__menu {
      border: none;
      margin-top: 0rem;
    }
    &__value-container {
      cursor: text;
      .react-select__input-container {
        .react-select__input {
          font-size: 1rem !important;
          width: auto !important;
        }
      }
    }
    &__option {
      background-color: var(--background-dark);
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.125rem;
      margin-top: 0.625rem;
      border-radius: 0.625rem;

      &--is-focused {
        background-color: var(--border-primary-active);
      }
      div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      .icon {
        float: left;
      }
      .label {
        float: left;
      }
    }

    &__dropdown-indicator {
      border: 1px solid var(--border-primary);
      border-radius: 0.25rem;
      padding: 0.75rem;
    }
  }
}
