.pratus-tabs-container {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .tab {
        background-color: inherit;
        font-size: 16px;
        font-weight: 400;
        color: var(--neutral-400);
        border: none;
        border-bottom: 1px solid var(--neutral-700);
        flex-grow: 1;
        padding: 0.5rem;
        //margin-right: 0.3125rem;
        text-transform: capitalize;

        &.active {
            color: var(--tech-blue-500);
            font-weight: 600;
            border-bottom: 2px solid var(--tech-blue-500);
            line-height: 24px;
        }
    }
}