main {
  height: calc(100vh - 4rem);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &.pratus-map {
    padding-bottom: 0;
  }

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-600;
    outline: none;
    @include radius(1rem);
  }

  .page-title {
    h1 {
      margin-top: 0.75rem;
      margin-bottom: 2.5rem;
    }

    .right-content {
      margin-bottom: 2.5rem;
    }
  }

  .maincontent {
    @include make-container();
    @include padding-bottom(2rem);
  }

}



/* Leftover styles from __dashboard.scss that mostly style dashboard blocks */
.site-main__dashboard {
  & > .container {
    & > .row {
      & > [class*="col-"] {
        margin: 1rem 0;
      }
    }
  }

  .block {
    border: solid 2px $gray-800;
    @include radius(10px);
    padding: 1rem;
    padding-bottom: 4rem;
    background: rgba($gray-800, 0);
    @include transition($transition);
    position: relative;
    height: auto;
    min-height: 100%;

    &.disabled {
      user-select: none;
      pointer-events: none;
      filter: grayscale(1);
    }

    &:hover {
      background: rgba($gray-800, 1);
    }

    h4 {
      @include line__height(26px);
    }

    p {
      @include font__size(13px);
      @include line__height(17px);
      height: 51px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        height: auto;
        overflow: visible;
      }
    }

    .more {
      width: 32px;
      height: 32px;
      @include radius(50%);
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-brand;
      position: absolute;
      right: 1rem;
      bottom: 1rem;

      img {
        height: 10px;
        width: auto;
      }
    }
  }
}
