@charset "UTF-8";

// 1. Utilities
@import './assets/sass/utilities/variables';
@import './assets/sass/utilities/functions';
@import './assets/sass/utilities/mixins';
@import './assets/sass/utilities/animation';
@import 'styles/variables';
@import 'styles/icon';

// 2. Vendors
@import './assets/sass/vendors/bootstrap';
@import './assets/sass/vendors/font-awesome';
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

// 3. Base
@import './styles/typography';
@import './styles/global';
@import './styles/root';
@import './styles/extraUtilities';
// reset has a bunch of utilities that need precedence over bootstrap utilities.
@import './assets/sass/base/reset';

// 4. Components
@import './styles/components/alert';
@import './styles/components/avatar';
@import './styles/components/button';
@import './styles/components/form';
@import './styles/components/modal';
@import './styles/components/table';
@import './styles/components/breadcrumb';
@import './styles/components/pagination';
@import './styles/components/loader';
@import './styles/components/dropdown';
@import './styles/components/tab';
@import './styles/components/calendar';
@import './styles/components/datetimepicker';
@import './styles/components/select';
@import './styles/components/steps';
@import './styles/components/accordion';
@import './styles/components/help';
@import './styles/components/texteditor';
@import './styles/components/carousel';
@import './styles/components/chartBox';
@import './styles/components/range-slider';
@import './styles/components/secondaryNav';
@import './styles/components/badge';
@import './styles/components/lists';
@import './styles/components/list-group';
@import './styles/components/toast';
@import './styles/components/iconContainerWithButton';
@import './styles/components/fullScreenModal';
@import './styles/components/pratusStepper';
@import './styles/components/autoSitrep';
@import './styles/components/pratusTab';
@import './styles/components/templateWizard';
@import './styles/components/offcanvas';

// 5. Layout
@import './styles/navigation';
@import './styles/maincontent';

// 6. Pages
@import './styles/pages/iap_dashboard';
@import './styles/pages/upgradeSubscription';
@import './styles/pages/map';

// 6.1. DICE
@import './assets/sass/modules/dice/authentication';
@import './assets/sass/modules/dice/incident-management';

// 6.2. PREPARE
@import './assets/sass/modules/prepare/settings';
@import './assets/sass/modules/prepare/plan';
@import './assets/sass/modules/prepare/notebook';
@import './assets/sass/modules/prepare/media';
@import './assets/sass/modules/prepare/no-route';
@import './assets/sass/modules/prepare/train';
@import './assets/sass/modules/prepare/mediaSocials';

.logo-container img {
  height: 4rem;
  width: 4rem;
}

/**
*   Utilities in the style of Tailwind.
*   In the future, we should enhance our Bootstrap utilities to behave more like Tailwind.
*/
.tw-border-b-neutral-700 {
  border-bottom-color: var(--neutral-700) !important;
}
.tw-mb-3 {
  margin-bottom: 0.75rem;
}
.tw-ps-3 {
  padding-left: 0.75rem;
}
.tw-text-xs {
  font-size: 0.75rem;
}
.tw-hover\:text-error {
  &:hover {
    color: var(--text-error);
  }
}
