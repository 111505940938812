/////////////////////////
// Plan Module
/////////////////////////

.site-main__plan {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .row.h-100,
  [class*='col-'].h-100 {
    @include media-breakpoint-down(md) {
      & {
        height: auto !important;
      }
    }
  }

  .section {
    &.with-separator {
      &--bottom {
        border-bottom: dashed 2px $gray-700;
      }
    }

    &__exercise-details {
      .list {
        li {
          position: relative;
          padding-left: 1.75rem;

          &+li {
            margin-top: 0.5rem;
          }

          &:before {
            @include fontawesome('\f04d');
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      .placeholders {
        height: 216px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }
    }

    &__msel-list {
      .list__msel {
        max-height: 562px;
        overflow-x: hidden;
        overflow-y: auto;

        @include media-breakpoint-down(md) {
          & {
            max-height: 400px;
          }
        }

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-100;
          outline: none;
          @include radius(6px);
        }

        .placeholders {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
        }

        li {
          background: $gray-800;
          @include radius(10px);
          @include transition($transition);
          padding: 1rem;
          @include font__size(13px);
          @include line__height(23px);

          &+li {
            margin-top: 0.5rem;
          }

          .status {
            @include line__height(30px);
          }
        }
      }
    }

    &__calendar {
      .placeholders {
        background: $gray-600;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 954px;

        @include media-breakpoint-down(md) {
          & {
            height: 400px;
          }
        }
      }

      .lists {
        overflow-x: hidden;
        overflow-y: auto;

        @include media-breakpoint-down(md) {
          & {
            height: 400px;
          }
        }
      }
    }

    &__event-item {
      position: relative;
      box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);
      min-height: 100%;
      @include transition($transition);
      @include font__size(12px);
      @include line__height(18px);
      font-weight: $font-weight--600;

      &:hover {
        box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.2);
      }

      h4 {
        color: $color-brand;
      }

      .color {
        width: 1rem;
        height: 1rem;
        @include radius(50%);
      }
    }

    &__participant-item,
    &__system-item,
    &__mission-item {
      position: relative;
      box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);
      min-height: 100%;
      @include transition($transition);

      &:hover {
        box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.2);
      }

      .title {
        min-height: 48px;
      }

      h4 {
        color: $color-brand;
        @include line__height(24px);
      }

      .icon {
        svg {
          width: 2.25rem;
          height: auto;
        }
      }

      .list {
        li {
          position: relative;
          padding-left: 1.75rem;

          &+li {
            margin-top: 0.5rem;
          }

          &:before {
            @include fontawesome('\f04d');
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      .details {
        height: 2rem;
        width: 2rem;
        @include radius(50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-secondary;
        position: absolute;
        bottom: -1rem;
        left: 50%;
        @include translate(-50%, 0);
        @include font__size(22px);
        @include line__height(32px);
        color: $color-white;
        box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);
        @include transition($transition);

        &:hover {
          transform: translate(-50%, 0) scale(1.1);
          box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .trr {
    position: relative;

    &>.bg-gray-700,
    &>.bg-gray-800 {
      box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.2);
      margin-left: 3rem;

      @include media-breakpoint-down(md) {
        & {
          margin-left: 2rem;
        }
      }
    }

    &__child {
      margin-left: 3rem;

      @include media-breakpoint-down(md) {
        & {
          margin-left: 2rem;
        }
      }
    }

    &.active {
      &:before {
        content: '';
        width: 1px;
        height: 100%;
        background: $gray-500;
        position: absolute;
        left: 0.75rem;
        top: 0;
      }
    }

    &.level3 {
      &:before {
        content: '';
        width: 1px;
        height: calc(100% + 1.5rem);
        background: $gray-500;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:last-child {
        &:before {
          height: 100%;
        }
      }

      &:after {
        content: '';
        height: 1px;
        width: 2rem;
        background: $gray-500;
        position: absolute;
        left: 0;
        top: 0;
      }

      &>div {
        @include media-breakpoint-down(md) {
          & {
            border-top-left-radius: 0 !important;
          }
        }
      }

      @include media-breakpoint-down(md) {
        & {
          &:before {
            left: 0.75rem;
          }

          &:after {
            width: 1.25rem;
            left: 0.75rem;
          }
        }
      }
    }

    &__toggle {
      background: $gray-500;
      width: 1.5rem;
      height: 1.5rem;
      @include radius(50%);
      position: absolute;
      right: calc(100% + 1.5rem);
      // top: 50%;
      // @include translate(0, -50%);
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      @include media-breakpoint-down(md) {
        & {
          right: calc(100% + 0.5rem);
        }
      }

      img {
        @include transition($transition);
        @include rotate(180deg);
        margin: -2px 0 0;
      }

      &.enabled {
        background: $color-brand;
      }

      &.active {
        background: $color-brand;

        img {
          @include rotate(0deg);
          margin: 2px 0 0;
        }
      }
    }

    &__form-selectbox {
      width: 300px;

      @include media-breakpoint-down(md) {
        & {
          width: 100%;
        }
      }
    }
  }

  .workspace__te {
    height: calc(100vh - (4.5rem + 4rem));

    @include media-breakpoint-down(xl) {
      & {
        height: auto !important;

        .row .row {
          height: auto;
        }
      }
    }

    .calendar {
      .fc {
        .fc {
          &-list-event {
            cursor: default;

            &:hover {
              .fc-list-event {
                &-title {
                  a {
                    text-decoration: none;
                  }
                }

                &-dot {
                  border-color: $color-white !important;
                }
              }
            }
          }
        }
      }
    }

    .block {

      &__calendar,
      &__mission {
        border-left: solid 1px #6c757d;
      }

      &__event,
      &__mission,
      &__calendar {
        display: flex;
        flex-direction: column;

        &>h4 {
          flex-shrink: 0;
        }

        &>div {
          flex-grow: 1;

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-track {
            background: none;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
            outline: none;
            @include radius(6px);
          }
        }
      }

      &__mission {
        position: relative;

        @include media-breakpoint-down(xl) {
          & {
            border: none;
            padding-top: 1.5rem;
            margin-top: 1.5rem;

            &:before {
              content: '';
              height: 1px;
              left: 1rem;
              right: 1rem;
              top: 0;
              background: #6c757d;
              position: absolute;
            }
          }
        }

        .scroll {
          height: calc(100vh - (4rem + 4.5rem + 30px + 5rem));
          overflow-x: hidden;
          overflow-y: auto;

          @include media-breakpoint-down(xl) {
            & {
              height: 392px;
            }
          }

          @include media-breakpoint-down(md) {
            & {
              height: auto;
              max-height: 304px;
            }
          }

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-track {
            background: none;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $gray-100;
            outline: none;
            @include radius(6px);
          }
        }

        .button-group {
          .button:first-child,
          button:first-child {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right-width: 1px;
          }

          .button:last-child,
          button:last-child {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left-width: 1px;
          }

          .button:not(:first-child):not(:last-child),
          button:not(:first-child):not(:last-child) {
            border-radius: 0;
            border-left-width: 1px;
            border-right-width: 1px;
          }
        }

        .custom-calendar {
          height: calc(100% - 100px);
          border: solid 1px var(--border-neutral);
          margin-top: 1rem;

          .list {
            .square {
              margin-top: 0.5rem;
            }

            li {
              border-bottom: solid 1px var(--border-neutral);
              padding-bottom: 0.5rem;
              margin-top: 0.5rem;

              &:before {
                padding-left: 0.5rem;
              }
            }
          }
        }
      }

      &__calendar {
        display: flex;
        flex-direction: column;
        position: relative;

        @include media-breakpoint-down(xl) {
          min-height: calc(100vh - (4.5rem + 4rem));
        }

        @include media-breakpoint-down(md) {
          min-height: auto;
          padding-top: 1.5rem;
          margin-top: 1.5rem;
          border: none;

          &:before {
            content: '';
            height: 1px;
            left: 1rem;
            right: 1rem;
            top: 0;
            background: #6c757d;
            position: absolute;
          }
        }

        h4 {
          flex-shrink: 0;
        }

        .calendar {
          flex-grow: 1;

          &>div {
            height: 100%;
            display: flex;
            flex-direction: column;

            .fc-header-toolbar {
              flex-shrink: 0;
            }

            .fc-view-harness {
              flex-grow: 1;
              height: auto !important;

              @include media-breakpoint-down(md) {
                height: 400px !important;
              }
            }
          }
        }
      }
    }

    .row {
      height: 100%;
    }
  }
}

.plan {
  &-prepare {
    &__events {
      .color {
        width: 1rem;
        height: 1rem;
        @include radius(50%);
      }

      .titlebar {
        .form-search {
          @include media-breakpoint-down(xl) {
            & {
              flex-grow: 1;
              margin-top: 1rem;
            }
          }

          @include media-breakpoint-down(md) {
            & {
              width: auto;
            }
          }
        }

        .help {
          @include media-breakpoint-down(xl) {
            & {
              flex-shrink: 0;
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  &-msel {
    .button-group {
      &.tab {
        .button {
          min-width: 110px;
        }
      }
    }
  }
}