.material-icon {
  font-size: 1.25rem;
}

.status-dot {
  position: relative;
  display: inline-block;
  padding: 0.5rem;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--text-weak);
  }

  &.success:before {
    background-color: var(--success-500);
  }

  &.danger:before {
    background-color: var(--error-500);
  }
}

.pratus-lock-icon {
  background: var(--brand-primary);
  @include radius(50%);
  height: 1.25rem;
  width: 1.25rem;
  @include font__size(12px);
  @include line__height(20px);
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
  color: var(--text-white);
}
