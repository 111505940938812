.teams-template-wizard-container {
  width: 100%;
  height: 100%;

  .left-content {
    background-color: var(--neutral-800);
    min-height: 24rem;
    width: 14rem;
    padding: 1.5rem 1rem;
    border-radius: 4px 0px 0px 4px;
    margin-right: 1.75rem;

    .helper-text-container {
      width: 11.8rem;
      height: 11.75rem;
      padding: 10px 12px;
      background-color: var(--neutral-700);
      border-radius: 8px;
      justify-content: space-around;

      .title {
        font-size: 12px;
        color: #fff;
        font-weight: 600;
      }

      .description {
        font-size: 12px;
        color: var(--neutral-400);
        text-align: left;
      }

      .dismiss-btn {
        border: none;
        font-size: 12px;
        font-weight: 600;
        background-color: inherit;
        color: var(--tech-blue-500);
        padding: 0;
        margin: 0;
      }
    }
  }

  .right-content {
    flex-grow: 1;
    padding: 1.5rem 1.5rem 1.5rem 0rem;

    .step {
      color: var(--text-weak);
    }

    .step-content {
      .title {
        font-size: 24px;
        font-weight: 600;
      }

      .description {
        font-size: 14px;
        font-weight: 600;
        color: var(--neutral-400);
        margin: 1.25rem 0px;
      }
    }

    .cancel-btn {
      background-color: inherit;
      border: none;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin: 10px 0px 0px 0px;
      padding: 0px;
    }

    .label {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .primary-btn {
    color: #fff;
    font-size: 14px;
    max-height: 35px;
    font-weight: 600;
    line-height: 18px;
    background-color: var(--tech-blue-700);
    border-radius: 4px;
    padding: 5px 10px;
    border: none;
  }

  .btn-blue-text {
    color: var(--tech-blue-700);
    font-size: 14px;
    max-height: 35px;
    font-weight: 600;
    line-height: 18px;
    background-color: inherit;
    border-radius: 4px;
    padding: 10px;
    border: none;

    &:hover {
      // color: var(--neutral-400);
    }
  }
}
