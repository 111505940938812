.list-group {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  & + .list-group {
    border-top: 1px solid var(--border-neutral);
  }

  .list-group-title {
    font-size: 0.625rem;
    line-height: 1.75;
    text-transform: uppercase;
    color: var(--text-weak);
    padding-left: 1.25rem;
  }

  .list-group-item {
    padding: 0.5rem 1.25rem;
    background-color: var(--background-dark);
    color: var(--text-white);
    display: flex;
    align-items: center;
    border: 0;
    @include transition($transition);
    text-align: left;

    &.secondary {
      margin-left: 2.25rem;
      border-left: 1px solid var(--border-neutral);
      padding-left: 1.75rem;
      display: flex;
      align-items: center;
    }

    &.disabled {
      pointer-events: auto;
      color: var(--text-weak);
    }

    .nav-icon {
      padding: 0.25rem;
      margin-right: 0.75rem;
      flex-shrink: 0;
    }

    &:focus,
    &:hover {
      background: var(--background-light);
    }

    &.active {
      border-right: 4px solid var(--brand-primary);
      padding-right: 1rem;

      i.nav-icon {
        background: var(--brand-primary);
        border-radius: 0.25rem;
        border: 1px solid transparent;
      }
    }
  }
}

body.user-is-tabbing {
  .list-group-item {
    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 4px var(--border-primary-focus);
      border-radius: 0.25rem;
      @include transition($transition);
    }
  }
}

// TODO: separate out the list group styles from what is specific to Nav sidebar
