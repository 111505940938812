/////////////////////////
// Calendar
/////////////////////////

.calendar {
  background: var(--background-inverse);
  .fc {
    .fc {
      &-toolbar-title {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.5rem;
      }

      &-button {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        padding: 0.5rem 0.75rem;

        text-transform: capitalize;
        box-shadow: none !important;

        &-primary {
          background-color: var(--background-primary);

          &:hover,
          &:not(:disabled):active,
          &:not(:disabled).fc-button-active,
          &:disabled {
            background-color: var(--background-primary-hover);
          }
        }
      }

      &-today-button {
        background-color: var(--background-secondary);

        &:hover,
        &:not(:disabled):active,
        &:not(:disabled).fc-button-active,
        &:disabled {
          background-color: var(--background-secondary-active);
        }
      }

      &-col-header {
        &-cell {
          text-align: left;

          &-cushion {
            color: var(--text-white);
            font-weight: 600;
          }
        }
      }

      &-daygrid-day {
        &-number {
          color: var(--text-white);
          font-weight: 600;
        }
      }

      &-more-link {
        //unused?
        color: var(--text-white);
        font-weight: 500;

        &:hover {
          color: var(--brand-primary);
        }
      }

      &-daygrid-day,
      &-timegrid-col {
        &.fc-day-today {
          background-color: var(--neutral-700);
        }
      }

      &-h-event,
      &-v-event {
        background-color: var(--brand-primary);
      }

      &-event-title {
        font-weight: 600;
        padding: 0 0.75rem;
      }

      &-daygrid-event {
        color: var(--text-white);
        background: var(--brand-primary);
        cursor: pointer;

        &-dot {
          display: none;
        }
        .fc-event-time {
          margin-left: 0.5rem;
        }
      }

      &-daygrid-event-harness {
        &:before {
          content: '';
          width: 5px;
          background: var(--success-100);
          position: absolute;
          left: 0;
          top: 1px;
          bottom: 0;
          z-index: 7;
          @include radius(3px 0 0 3px);
          display: block;
        }
      }

      &-timegrid-axis-cushion,
      &-timegrid-slot-label-cushion {
        font-weight: 600;
      }

      &-list-event {
        cursor: pointer;

        &-title {
          a {
            font-weight: 500;
          }
        }

        &-dot {
          border-radius: 0px;
          border-color: var(--text-white);
        }

        &:hover {
          td {
            background: none;
          }

          .fc-list-event {
            &-title {
              a {
                text-decoration: underline;
              }
            }

            &-dot {
              border-color: var(--organizations-salmon);
            }
          }
        }
      }

      &-list-empty {
        background-color: var(--neutral-600);

        &-cushion {
          font-weight: 600;
        }
      }

      &-event-selected:after,
      &-event:focus:after {
        background: none;
      }

      &-toolbar {
        @include media-breakpoint-down(md) {
          & {
            display: block;

            .fc-toolbar-chunk {
              &:first-child {
                display: flex;
                justify-content: space-between;
              }

              &:last-child {
                .fc-button-group {
                  width: 100%;
                  display: flex;
                }
              }

              &:not(:first-child):not(:last-child) {
                text-align: center;
                padding: 0.5rem 0;
              }
            }
          }
        }
      }

      &-more-popover {
        //unused?
        .fc-popover-body {
          padding: 0 0.5rem 0.5rem;
        }
      }
    }

    &.fc-theme-standard {
      td,
      th,
      .fc-scrollgrid,
      .fc-list {
        border-color: var(--neutral-600);
      }

      .fc-list-day-cushion {
        background-color: var(--neutral-600);

        a {
          color: var(--text-white);
          font-weight: 600;
        }
      }

      .fc-popover {
        //unused?
        background: var(--neutral-700);
        @include radius(5px);
        border: none;
        box-shadow: 0 0 0.75rem rgba($color-black, 0.15) !important;
        z-index: 1 !important;

        &-header {
          padding: 0.5rem;
          background: none;
        }

        &-title {
          margin: 0;
          font-weight: 500;
        }

        &-close {
          opacity: 0.75;
          font-size: 1.25rem;
          @include transition($transition);

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &-dashboard {
    //unused?
    .fc {
      .fc-toolbar.fc-header-toolbar {
        background: var(--neutral-800);
        padding: 0.5rem 0.75rem;
        margin-bottom: 0.75rem;
      }

      .fc-view-harness {
        height: 884px !important;

        @include media-breakpoint-down(md) {
          & {
            height: 400px !important;
          }
        }
      }

      .fc-scroller {
        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--neutral-200);
          outline: none;
          @include radius(6px);
        }
      }

      .fc-toolbar.fc-header-toolbar {
        @include media-breakpoint-down(md) {
          & {
            display: flex;
            flex-direction: column;
          }
        }

        .fc-toolbar-chunk {
          @include media-breakpoint-down(md) {
            &:empty {
              display: none;
            }

            &:first-child {
              display: block;
            }

            &:last-child {
              margin-top: 0.25rem;
              display: flex;
              justify-content: space-between;
              width: 100%;

              .fc-button-group {
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    & {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }
}
