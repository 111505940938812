.steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .step {
    font-weight: 700;
    font-size: 12px;
    line-height: 2;
    color: var(--neutral-400);

    .step-number {
      border: 1px solid var(--neutral-700);
      border-radius: 24px;
      padding: 6px 10px;
      margin-right: 10px;

      &.active {
        color: #fff;
        background-color: var(--tech-blue-500);
      }

      &.completed {
        border-color: var(--tech-blue-500);
        padding: 6px 2px;
      }
    }

    .step-label {
      color: var(--neutral-400);
      font-size: 12px;
      font-weight: 400;

      &.active {
        font-weight: 700;
      }
    }
  }

  .line {
    height: 1px;
    flex-grow: 1;
    background-color: var(--neutral-700);
    margin: 0 10px;

    &.vertical {
        width: 1px;
        margin: 10px 0px 10px 13px;
        height: 2.6rem;
    }

    &.active {
      background-color: var(--tech-blue-500);
    }
  }
}
