/////////////////////////////
// Module Authentication
/////////////////////////////

.site-authentication {
    padding: 2.25rem 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    text-align: center;

    &::-webkit-scrollbar {
        width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray-600;
        outline: none;
        @include radius(1rem);
    }

    @include media-breakpoint-down(md) {
        & {
            padding: 2rem 0;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;

        img {
            height: 3.5rem;
            width: auto;

            @include media-breakpoint-down(md) {
                & {
                    height: 4.5rem;
                }
            }
        }
    }
}