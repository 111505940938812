.help-icon a {
  line-height: 0.675;
}

body.user-is-tabbing .help-icon a:focus {
  border-radius: 0.25rem;
  outline: none;
  box-shadow: 0 0 0 4px var(--border-primary-focus);
}


#sidebar-nav {
  position: fixed;
  top: 4rem;
  left: 0;
  bottom: 0;
  width: 18rem;
  min-height: calc(100% - 4rem);
  z-index: $zindex-dropdown;
  background-color: var(--background-dark);
  transition: $transition;
  transform: translateX(-18rem);

  &.open {
    transform: translateX(0);
  }

  .sidebar-nav-content {
    overflow: hidden auto;
    // 4rem for the header + 4rem for the nav footer
    max-height: calc(100vh - 8rem);
  }

  .list-group-item {
    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }

  .sidebar-nav-footer {
    position: fixed;
    bottom: 0;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 0.75rem;
    color: var(--text-weak);

    background-color: var(--background-dark);
  }
  
  .org-label {
    display: flex;
    align-items: center;
    padding: 1.25rem 1rem 0.5rem 1rem;
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .name-container {
    overflow: hidden;
    flex-grow: 1;
  }
  
  .word-break {
    word-break: break-word;
  }
  
  .second-panel-back {
    padding: 1.5rem 1.25rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .nav-slide-enter {
    transform: translateX(-100%);
  }

  .nav-slide-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
  }

  .nav-slide-exit {
    transform: translateX(0);
  }

  .nav-slide-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms;
  }

  .nav-slide-enter.second-panel {
    transform: translateX(100%);
    opacity: 0;
  }

  .nav-slide-enter-active.second-panel {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms, opacity 300ms;
  }

  .nav-slide-exit.second-panel {
    transform: translateX(0);
    opacity: 1;
  }

  .nav-slide-exit-active.second-panel {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 300ms, opacity 300ms;
  }
}

