//////////////////////////////////////////////////
// Module :: Incident Management
//////////////////////////////////////////////////

.site-main__incident-management {
    .dashboard {
        &__sidebar {
            background: $gray-800;
            @include radius(10px);
            padding: 1rem;

            @include media-breakpoint-down(xl) {
                &.hide {
                    display: none !important;
                }

                &.show {
                    display: block !important;
                }
            }

            .section {
                border-bottom: dashed 2px $gray-700;
                padding: 1rem 0;

                &:last-of-type {
                    border: none;
                }
            }

            h6 {
                margin: 0 0 .5rem;
                text-transform: uppercase;

                &~h6 {
                    margin-top: .75rem;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    &+li {
                        margin-top: .125rem;
                    }

                    a {
                        color: $color-white !important;
                        @include transition($transition);
                        display: flex;
                        align-items: center;
                        padding: .375rem 1rem;

                        &.active,
                        &:hover {
                            background: $gray-700;
                        }

                        &:before {
                            @include fontawesome('\f105');
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }

        .list {
            &__assignments {
                overflow-x: hidden;
                overflow-y: auto;
                height: 430px;

                &::-webkit-scrollbar {
                    width: 3px;
                }

                &::-webkit-scrollbar-track {
                    background: none;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $gray-100;
                    outline: none;
                    @include radius(6px);
                }
            }
        }

        .calendar {
            .fc-view-harness {
                height: 788px !important;

                @include media-breakpoint-down(md) {
                    & {
                        height: 500px !important;
                    }
                }
            }
        }

        .map {
            height: 858px !important;

            @include media-breakpoint-down(md) {
                & {
                    height: 500px !important;
                }
            }

            .mapboxgl-canvas-container {
                height: 100% !important;

                canvas {
                    display: block;
                    height: 100% !important;
                }
            }
        }
    }
}