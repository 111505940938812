/////////////////////////
// Modal
/////////////////////////

.modal {
  &-dialog {
    &.modal-lg {
      @include media-breakpoint-up(sm) {
        max-width: calc(768px - 2rem);
      }
    }
  }

  &-content {
    background: var(--background-dark);
    border-radius: 0.5rem;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.3);

    .table {
      thead {
        th {
          background: var(--background-dark);
        }
      }

      tbody {
        td {
          border-bottom-color: var(--neutral-500);
        }
      }

      @include media-breakpoint-down(md) {
        & {
          tbody {
            tr {
              border-color: var(--neutral-500);
            }
          }
        }
      }
    }
  }

  &-title {
    @extend .break-word;
    flex-grow: 1;
    font-size: 1.125rem !important;
    font-weight: 700 !important;
    line-height: 1.75rem !important;
    margin-bottom: 0rem !important;
  }

  &-header {
    display: flex;
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    align-items: center;
    gap: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid var(--border-neutral);

    @include media-breakpoint-down(md) {
      & {
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .btn-close {
      opacity: 1;
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      padding: 0.5rem;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      border-radius: 0.25rem;
      box-shadow: 0px 1px 2px 0px rgba(16, 40, 34, 0.05);
    }
  }

  &-body {
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  }

  &-footer {
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    align-items: normal;
    gap: 1rem;
    border-top: none;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: var(--background-light);

    @include media-breakpoint-down(md) {
      & {
        justify-content: flex-start;
        padding-left: 1rem;
        padding-right: 1rem;

        .button-group {
          & {
            flex-direction: column;
            align-items: normal;
            width: 100%;

            .button {
              & + .button {
                margin: 1rem 0 0;
              }
            }
          }
        }
      }
    }
  }

  &-fullscreen-xl-down {
    .modal {
      &-body {
        &::-webkit-scrollbar {
          width: 0.25rem;
        }

        &::-webkit-scrollbar-track {
          background: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--neutral-400);
          outline: none;
          @include radius(1rem);
        }
      }
    }
  }
}
