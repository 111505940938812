// .site-main__mediafff {
.site-main__media {
  .icon {
    &-social {
      width: 45px;
      height: 45px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 1.5rem;
        width: auto;
      }
    }
  }

  .form {
    &-block {
      .p {
        &--l {
          @include line__height(24px);
        }
      }

      .social {
        &-item {
          border-bottom: 1px solid $gray-600;

          &:nth-last-child(-n + 2) {
            border-bottom: 0;
          }

          &-pic {
            width: 4rem;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .card {
    &-banner {
      width: 100%;
      height: 10rem;
      background: url(./../../../../assets/images/icon__choosemedia.svg)
        $gray-700 no-repeat;
      background-position: center;
      background-size: 3rem auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &__big {
        height: 18rem;
      }
    }

    &-content {
      // position: relative;
      // padding-top: 3rem !important;

      svg {
        // fill: $color-brand;
        margin-right: 0.5rem;
        height: 1rem;
        width: auto;
      }

      .profile {
        &-img {
          top: -2rem;
        }
      }

      .top-80 {
        top: -5rem;
      }

      &__pt-80 {
        padding-top: 5rem !important;
      }
    }
  }

  .link {
    &-listview {
      border: 1px solid $gray-600;
      border-radius: 0.625rem;

      p {
        border-bottom: 1px solid $gray-600;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
.posts {
  &-row {
    &:last-child {
      & hr {
        display: none;
      }
    }
    .row--status {
      @include media-breakpoint-up(md) {
        width: calc(100% - 85px);
      }
    }
  }
  &-DateTimePicker {
    .row {
      > div {
        &:first-child {
          @include media-breakpoint-up(md) {
            padding-right: 0;
          }
        }
      }
    }
  }

  &-tab {
    a,
    button {
      font-size: 1rem;
      padding: 0.5rem;
      color: #fff;
      background-color: transparent;
      font-weight: $font-weight--400;
    }

    .badge {
      &--posts {
        padding: 0.125rem 0.5rem;
        min-width: 35px;
        height: auto;
        min-width: 35px;
        height: 35px;
        margin-left: 0.4rem;
        text-align: center;
        @include font__size(13px);
        @include line__height(30px);
        @include radius(0.5rem);
      }
    }
  }
}
.line {
  &-heading {
    hr {
      background: $gray-700;
      height: 2px;
    }
    span {
      background: $gray-800;
    }
  }
}

.profile {
  &-img {
    width: 65px;
    height: 65px;
    position: relative;

    & img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      position: absolute;
      z-index: 1;
      object-fit: cover;
      left: 0;
    }

    &::after {
      border: 2px solid #6a737b;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      @include radius(10px);
      background: url(./../../../../assets/images/icon__emptyprofile.svg)
        no-repeat;
      background-position: center;
      background-size: 28px auto;
      background-color: #353a40;
      left: 0;
    }
  }

  &-img__big {
    width: 9rem;
    height: 9rem;
    &::after {
      background-size: 40px auto;
    }
  }
}
