.secondary-nav {
  display: flex;
  padding: 1rem;
}

.secondary-left-nav {
  flex: 0 0 auto;
  padding: 1rem;
  background-color: var(--background-dark);
  border: solid 1px var(--background-dark);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

  ul {
    list-style: none;
    padding-left: 0;
  }

  li a {
    padding: 0.5rem 0.75rem;
    color: var(--text-white);
    border-radius: 0.25rem;
    border: solid 1px var(--background-dark);
    text-decoration: none;
    display: block;

    &.active {
      background-color: var(--background-light);
      border: solid 1px var(--background-light);
    }

    &.disabled,
    &.locked {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--text-weak);
    }
  }
}

.secondary-right-content {
  width: 100%;
  padding: 1.5rem;

  .nav-content-header {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  /* TODO: look into whether these can be done with --bs-card-{var}s */
  .card {
    background-color: var(--background-light);
    border: solid 1px var(--background-light);
    border-radius: 0.25rem;
    flex-direction: row;
    padding: 1.5rem;
  }
}
