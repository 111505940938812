/////////////////////////
// Table
/////////////////////////

.table {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem; /* 150% */

  &.layout-auto {
    table-layout: auto;
  }

  thead,
  tbody {
    th,
    td {
      border: none;
      color: var(--text-white);
      vertical-align: middle;
      word-wrap: break-word;
      white-space: normal;
      font-weight: 600;

      .row {
        color: var(--text-white);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
  }

  &.v-top {
    thead,
    tbody {
      th,
      td {
        vertical-align: top;
      }
    }
  }

  thead {
    th {
      color: var(--text-disabled);
      font-size: 0.625rem;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 1px solid var(--border-primary);

      &.sortable {
        @include transition($transition);
        cursor: pointer;

        .order {
          display: inline-flex;
          align-items: center;

          .caret {
            margin: 0;
          }

          .dropdown {
            .caret {
              width: 1rem;
              height: 1rem;
              background: url(/assets/images/keyboard_arrow_down.svg) no-repeat
                0 0;
              transform: rotate(180deg);
              display: block;
            }
          }

          .dropup {
            margin-left: 0.125rem;
            .caret {
              width: 1rem;
              height: 1rem;
              background: url(/assets/images/keyboard_arrow_down.svg) no-repeat
                0 0;
              transform: none;
              display: block;
            }
          }
        }

        .react-bootstrap-table-sort-order {
          .caret {
            width: 1rem;
            height: 1rem;
            background: url(/assets/images/keyboard_arrow_down.svg) no-repeat 0
              0;
            display: block;
            transform: rotate(180deg);
            float: right;
            margin: 0;
          }

          &.dropup {
            .caret {
              width: 1rem;
              height: 1rem;
              background: url(/assets/images/keyboard_arrow_down.svg) no-repeat
                0 0;
              transform: none;
              display: block;
            }
          }
        }
      }

      &[data-row-selection] {
        width: 3.25rem !important;
      }

      .filter-label {
        width: 100%;
        display: block;
        margin: 0.5rem 0 0;

        .form-control {
          border: none;
          background: none;
          @include radius(0);
          border-bottom: solid 2px var(--neutral-700);
          padding: 0 0 0.375rem;
          font-weight: 500;

          &::-webkit-input-placeholder {
            color: var(--text-white);
            opacity: 0.75;
          }

          &:-ms-input-placeholder {
            color: var(--text-white);
            opacity: 0.75;
          }

          &::placeholder {
            color: var(--text-white);
            opacity: 0.75;
          }
        }
      }
    }
  }

  tbody {
    td {
      border-bottom: solid 1px var(--border-neutral);

      &.child-expand-tr {
        box-shadow: none;
        padding: 0.75rem;

        .row-expansion-style {
          padding: 0.75rem 1.5rem 1.25rem 1.5rem;
          border-radius: 0.25rem;
          background-color: var(--background-dark);
        }

        .nav {
          li {
            margin: 0.25rem 0.5rem 0.25rem 0;
          }
        }
      }
    }
  }

  .expand-cell,
  .expand-cell-header {
    &:before {
      display: none;
    }
  }

  .selection-cell,
  .selection-cell-header {
    @include media-breakpoint-up(md) {
      & {
        width: 3.25rem !important;
      }
    }

    &:before {
      display: none;
    }
  }

  .selection-cell-header {
    .form-checkbox {
      [type='checkbox'] {
        &:checked {
          & + .icon {
            border-color: var(--neutral-800);
          }
        }
      }
    }
  }

  &,
  & *,
  & > :not(caption) > * > * {
    box-shadow: none;

    .form-label {
      color: var(--text-weak);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;

      &-header {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.125rem;
        border-bottom: 1px solid var(--border-neutral);
        padding-bottom: 0.75rem;
      }
    }
  }

  .button {
    &--action {
      background: none;
      box-shadow: none;
      outline: none;
      border: solid 2px var(--neutral-600);
      width: 34px;
      height: 34px;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      vertical-align: top;
      color: var(--text-white);
      @include font__size(16px);
      @include line__height(24px);
      @include transition($transition);

      img {
        height: 1rem;
        width: auto;
      }

      &:hover {
        border-color: var(--brand-primary);
        background: none;
      }

      &.remove {
        border-color: var(--border-error);
      }

      &.add {
        border-color: var(--success-400);
      }
    }
  }

  .dropdown-actions {
    width: 20px; // This is super important for the ::before outline to look square
    margin: auto;

    .dropdown {
      &-toggle {
        background: none;
        box-shadow: none;
        outline: none;
        border: none;
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: top;
        color: var(--text-white);
        @include transition($transition);

        &::before {
          // create fake padding for the menu dropdown element
          content: '';
          position: absolute;
          top: -7px;
          left: -7px;
          right: -7px;
          bottom: -7px;
          border-radius: 0.25rem;
          z-index: 1;
        }

        &:hover::before {
          border: 1px solid var(--brand-primary);
        }

        &:after {
          display: none;
        }

        &:hover {
          border-color: var(--brand-primary);
          background: none;
        }
      }

      &-menu {
        padding: 0.25rem 0rem;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0.25rem;
        border: 1px solid var(--border-primary);
        background: var(--background-dark);
        box-shadow: 0px 4px 6px -2px rgba(34, 34, 34, 0.03),
          0px 12px 16px -4px rgba(34, 34, 34, 0.08);
      }

      &-item {
        color: var(--text-weak) !important;
        font-size: 0.75rem;
        line-height: 1.125rem; /* 150% */
        display: flex;
        padding: 0.625rem 0.75rem;
        align-self: stretch;
        img {
          height: 14px;
          width: auto;
          margin-right: 0.5rem;
          color: var(--text-weak) !important;
        }

        & + .dropdown-item {
          margin-top: 0.25rem;
        }

        &:active,
        &:focus {
          background: none;
        }

        &:hover {
          background: var(--background-light);
          color: var(--text-white) !important;
        }
      }
    }

    &.show {
      .dropdown {
        &-toggle {
          border-color: var(--brand-primary);
          background: none;
        }
      }
    }
  }

  &-hurricane {
    th {
      padding: 0;
      font-size: 10px;
    }
  }
}

.table-scroll {
  position: relative;
  max-height: 295px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--neutral-100);
    outline: none;
    // @include radius(6px);
  }

  thead {
    position: relative;
    z-index: 99;

    th {
      position: sticky;
      top: 0;
    }
  }
}

.table {
  &.tbody {
    &-p-0 {
      tbody {
        td {
          padding: 0;
        }
      }
    }
  }

  &.nowrap {
    th,
    td {
      white-space: nowrap;
    }
  }
}

// Responsive

.table {
  @include media-breakpoint-down(md) {
    & {
      display: block;

      [title='Action'] {
        text-align: left !important;
      }
    }
  }

  .sm-hidden {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  thead,
  tbody {
    th,
    td {
      @include media-breakpoint-down(md) {
        & {
          &:before {
            content: attr(title);
            margin-right: 0.375rem;
            // margin-bottom: .25rem;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  tbody {
    td {
      @include media-breakpoint-down(md) {
        & {
          &:before {
            margin-right: 0;
            margin-bottom: 0.375rem;
          }
        }
      }
    }
  }

  thead {
    th {
      &.sortable {
        @include media-breakpoint-up(md) {
          &:hover {
            box-shadow: inset 0px -2px 0px var(--text-white);
          }
        }

        .order {
          @include media-breakpoint-up(md) {
            & {
              display: none;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      & {
        width: 100%;
        display: flex;
        tr {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 0.5rem 1rem;

          &.empty {
            display: none;
          }
        }

        th {
          display: flex;
          background: none;
          padding: 0.25rem 0;
          margin-right: 1rem;

          &.expand-cell-header,
          &[data-row-selection='true'] {
            display: none;
          }

          &.selection-cell-header {
            width: 1.25rem !important;
          }

          &:not(.sortable) {
            display: none;
          }

          &.sortable {
            & ~ .sortable {
              &:before {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  tbody {
    @include media-breakpoint-down(md) {
      & {
        width: 100%;
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          width: 100%;
          flex-direction: column;
          border: solid 2px var(--neutral-800);
          margin-top: 1rem;

          &.parent-expand-tr + tr {
            margin: 0;
          }
        }

        td {
          display: flex;
          border: none;
          border-bottom: solid 1px var(--neutral-800);
          flex-direction: column;

          &::before {
            font-weight: 600;
          }

          &:last-child {
            border: none;
          }

          &.react-bs-table-no-data {
            text-align: center;
            justify-content: center;

            &:before {
              display: none;
            }
          }

          &.child-expand-tr {
            text-align: left;

            &::before {
              display: none;
            }

            .button-group:not(.switcher) {
              flex-wrap: wrap;

              .button {
                width: 100%;

                & + .button {
                  margin: 1rem 0 0;
                }
              }
            }

            .button-group.switcher {
              .button {
                width: 50%;

                & + .button {
                  margin: 0;
                }
              }
            }
          }

          &[style*='text-align: center'] {
            text-align: left !important;
          }
        }
      }
    }
  }

  .expand-cell,
  .expand-cell-header {
    @include media-breakpoint-up(md) {
      & {
        width: 50px;
        text-align: center;
        cursor: pointer;

        span {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(md) {
      & {
        justify-content: flex-start;
        background: var(--neutral-800);

        &:empty {
          display: none;
        }
      }
    }
  }
}

.table.no-filter {
  tbody {
    tr {
      @include media-breakpoint-down(md) {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.table.short {
  width: 50%;
  margin-left: 5rem;
}

.table.with-filter-sort {
  @include media-breakpoint-down(md) {
    & {
      thead {
        th {
          flex-wrap: wrap;
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

// React Table Per Page & Pagination

.react-bootstrap-table-pagination {
  margin: 1.5rem 0 0;

  [class*='col-'] {
    width: auto;
    padding: 0;
  }

  &-list {
    margin-left: auto;
  }

  .dropdown-toggle {
    background: var(--neutral-600);
    // @include radius(3px);
    width: auto;
    height: auto;
    @include font__size(14px);
    @include line__height(28px);
    border: none;
    font-weight: 600;
    padding: 0 0.5rem;

    &:hover,
    &:active {
      background: var(--brand-primary) !important;
      border: none !important;
    }

    & > span {
      display: none;
    }

    &:after {
      @include fontawesome('\f107');
      @include font__size(18px);
      border: none;
      margin: 0;
      margin-left: 0.5rem;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      a {
        color: var(--text-white);
        display: flex;
        flex-grow: 1;
      }
    }
  }
}

/**
  * Momentum Design Inspired table styles.
  * These have backgrounds and borders.
  * I have literally copied the style above and I'm overriding things that need it. 
  */
.pratus-table {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;

  color: var(--text-white);

  //border-radius: 0.25rem;
  //border: 1px solid var(--border-neutral);
  //overflow: hidden;
  //border-collapse: collapse;
  //border-spacing: 1px;
  //border: 0;
  //box-shadow: 0 0 1px var(--border-neutral);

  border: 1px solid var(--border-neutral);
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 0.25rem;
  background: var(--background-inverse);

  td,
  th {
    border-top: 1px solid var(--border-neutral);
    border-left: 1px solid var(--border-neutral);
  }

  td + td,
  th + th {
    border-left-width: 0;
  }

  tbody tr:first-child td,
  tbody:first-child tr:first-child td,
  thead:first-child tr:first-child th {
    border-top-width: 0;
  }

  tbody:last-child tr:last-child td {
    border-bottom-width: 0;
  }

  thead:first-child tr:first-child td:first-child,
  thead:first-child tr:first-child th:first-child {
    border-top-left-radius: 0.25rem;
  }

  thead:first-child tr:first-child td:last-child,
  thead:first-child tr:first-child th:last-child {
    border-top-right-radius: 0.25rem;
  }

  tbody:last-child tr:last-child td:first-child,
  tbody:last-child tr:last-child th:first-child {
    border-bottom-left-radius: 0.25rem;
  }
  tbody:last-child tr:last-child td:last-child,
  tbody:last-child tr:last-child th:last-child {
    border-bottom-right-radius: 0.25rem;
  }

  th,
  td {
    padding: 1rem;
    color: var(--text-white);
    vertical-align: middle;
    word-wrap: break-word;
    white-space: normal;
    font-weight: 600;
  }

  thead {
    th {
      color: var(--text-weak);
      font-size: 0.625rem;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 1px solid var(--border-primary);
    }
  }

  tbody {
    td {
      //border-bottom: solid 1px var(--border-neutral);
    }
  }
}
