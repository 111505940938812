/////////////////////////////////////
// Upgrade Subscription Modal
/////////////////////////////////////

.modal-upgrade {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: var(--background-primary-dark);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  &__header {
    padding: 0.875rem 2rem;
    flex-shrink: 0;

    @include media-breakpoint-down(md) {
      & {
        padding: 1rem;
      }
    }

    & > span {
      user-select: none;
      display: inline-flex;
      align-items: center;
      vertical-align: top;
      @include font__size(12px);
      @include line__height(20px);
      font-weight: $font-weight--500;
      color: $color-white;
      text-transform: uppercase;
      cursor: pointer;
      @include transition($transition);

      & > i {
        margin-right: 0.5rem;
        @include font__size(16px);
        @include line__height(20px);
        color: $color-white;
        @include transition($transition);
      }

      &:hover {
        color: var(--tech-blue-500);

        & > i {
          color: var(--tech-blue-500);
        }
      }
    }
  }

  &__body {
    flex-grow: 1;
    background: url(/assets/images/texture__upgradeModal.svg) no-repeat left top /
      auto 100%;
    padding: 3rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(md) {
      & {
        padding: 1rem 1rem 2rem;
        background-size: 100% auto;
        background-position: center top;
      }
    }

    & > .row {
      .row {
        align-items: center;

        & > [class*='col-'] {
          &:first-child {
            h3 {
              margin: 0 0 2rem;

              @include media-breakpoint-down(md) {
                & {
                  margin: 0 0 1.5rem;
                }
              }
            }

            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                @include font__size(16px);
                @include line__height(24px);
                font-weight: $font-weight--400;
                color: $color-white;
                position: relative;
                display: block;
                padding-left: 2.5rem;

                &:before {
                  font-family: 'Material Symbols Outlined';
                  content: '\e5ca';
                  color: var(--brand-primary);
                  @include font__size(24px);
                  @include line__height(24px);
                  position: absolute;
                  left: 0;
                  top: 0;
                }

                &:not(:first-child) {
                  margin-top: 1.5rem;

                  @include media-breakpoint-down(md) {
                    & {
                      margin-top: 1rem;
                    }
                  }
                }
              }
            }
          }

          &:last-child {
            .block {
              background: var(--neutral-900);
              border: solid 1px var(--neutral-700);
              @include radius(0.5rem);
              padding: 4rem 2rem;
              text-align: center;

              @include media-breakpoint-down(md) {
                & {
                  padding: 2rem 1rem;
                }
              }

              &__catchline {
                display: flex;
                align-items: center;
                justify-content: center;
                @include font__size(16px);
                @include line__height(24px);
                font-weight: $font-weight--600;
                color: var(--neutral-400);
                margin-bottom: 2rem;

                .icon {
                  background: var(--color-royalblue);
                  @include radius(50%);
                  width: 2rem;
                  height: 2rem;
                  @include font__size(18px);
                  @include line__height(32px);
                  text-align: center;
                  margin-right: 0.5rem;
                  color: $color-white;
                }
              }

              h2 {
                margin: 0 0 2rem;
              }

              hr {
                margin-top: 2rem;
                margin-bottom: 0;
              }

              p {
                @include font__size(14px);
                @include line__height(20px);
                font-weight: $font-weight--400;
                color: var(--neutral-400);
                margin-bottom: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}
