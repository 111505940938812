/////////////////////////
// Variables
/////////////////////////

$color-brand: #0099FF;
$color-secondary: #FD8119;
$color-white: #ffffff;
$color-black: #000000;

$font-primary: 'Montserrat',
sans-serif;
$font-awesome: 'FontAwesome';

$font-weight--100: 100;
$font-weight--200: 200;
$font-weight--300: 300;
$font-weight--400: 400;
$font-weight--500: 500;
$font-weight--600: 600;
$font-weight--700: 700;
$font-weight--800: 800;
$font-weight--900: 900;

$transition: all 0.5s ease;

// Bootstrap

$grid-gutter-width: 2rem;

$form-range-track-height: .375rem;
$form-range-track-bg: #212529; // $gray-900
$form-range-track-border-radius: .125rem;
$form-range-thumb-width: 1.5rem;
$form-range-thumb-bg: $color-brand;
$form-range-thumb-box-shadow: 0 .1rem .25rem rgba($color-black, .1);
$form-range-thumb-focus-box-shadow: $form-range-thumb-box-shadow;
$form-range-thumb-active-bg: $color-brand;
$form-range-thumb-disabled-bg: $color-white;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: .25rem;