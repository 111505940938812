/////////////////////////
// Text Editor
/////////////////////////

.form-editor {
  background: $gray-700;
  @include radius(10px);
  padding: 0 1rem;
  position: relative;
  z-index: 2;

  .ql {
    &-snow {
      a {
        color: $color-brand;
      }

      .ql {
        &-stroke {
          stroke: $color-white;
        }

        &-fill {
          fill: $color-white;
        }

        &-stroke {
          &.ql-fill {
            fill: $color-white;
          }
        }

        &-picker {
          color: $color-white;

          &.ql-expanded {
            .ql-picker-options {
              margin-top: 0;
            }
          }
        }

        &-picker-options {
          background: $gray-700;
          padding: 0.5rem 1rem;
        }
      }
    }

    &-toolbar {
      &.ql-snow {
        border: none;
        border-bottom: solid 1px $gray-600;
        padding: 0.75rem 0;
        font-family: $font-primary;

        .ql-picker {
          &.ql-expanded {
            .ql-picker-label {
              border-color: $gray-600;
            }
          }

          &-options {
            z-index: 1;
            border: none;
            @include radius(5px);
            box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    &-container {
      font-family: $font-primary;
      height: 100px;

      &.ql-snow {
        border: none;
      }
    }

    &-editor {
      padding: 1rem 0;

      &.ql-blank {
        &:before {
          left: 0;
          right: 0;
          color: $color-white;
          opacity: 0.75;
          font-style: normal;
        }
      }
    }
  }

  .ql-snow.ql-toolbar {
    button {
      &:hover {
        color: rgba($color-brand, 1);
      }
      &:focus {
        color: rgba($color-brand, 1);
      }
    }
    button.ql-active {
      color: rgba($color-brand, 1);
    }
    .ql-picker-label {
      &:hover {
        color: rgba($color-brand, 1);
      }
    }
    .ql-picker-label.ql-active {
      color: rgba($color-brand, 1);
    }
    .ql-picker-item {
      &:hover {
        color: rgba($color-brand, 1);
      }
    }
    .ql-picker-item.ql-selected {
      color: rgba($color-brand, 1);
    }
  }

  .ql-snow {
    .ql-toolbar {
      button {
        &:hover {
          color: rgba($color-brand, 1);
        }
        &:focus {
          color: rgba($color-brand, 1);
        }
      }
      button.ql-active {
        color: rgba($color-brand, 1);
      }
      .ql-picker-label {
        &:hover {
          color: rgba($color-brand, 1);
        }
      }
      .ql-picker-label.ql-active {
        color: rgba($color-brand, 1);
      }
      .ql-picker-item {
        &:hover {
          color: rgba($color-brand, 1);
        }
      }
      .ql-picker-item.ql-selected {
        color: rgba($color-brand, 1);
      }
    }
  }

  .ql-snow.ql-toolbar {
    button {
      &:hover {
        .ql-stroke {
          stroke: rgba($color-brand, 1);
        }
        .ql-stroke-miter {
          stroke: rgba($color-brand, 1);
        }
      }
      &:focus {
        .ql-stroke {
          stroke: rgba($color-brand, 1);
        }
        .ql-stroke-miter {
          stroke: rgba($color-brand, 1);
        }
      }
    }
    button.ql-active {
      .ql-stroke {
        stroke: rgba($color-brand, 1);
      }
      .ql-stroke-miter {
        stroke: rgba($color-brand, 1);
      }
    }
    .ql-picker-label {
      &:hover {
        .ql-stroke {
          stroke: rgba($color-brand, 1);
        }
        .ql-stroke-miter {
          stroke: rgba($color-brand, 1);
        }
      }
    }
    .ql-picker-label.ql-active {
      .ql-stroke {
        stroke: rgba($color-brand, 1);
      }
      .ql-stroke-miter {
        stroke: rgba($color-brand, 1);
      }
    }
    .ql-picker-item {
      &:hover {
        .ql-stroke {
          stroke: rgba($color-brand, 1);
        }
        .ql-stroke-miter {
          stroke: rgba($color-brand, 1);
        }
      }
    }
    .ql-picker-item.ql-selected {
      .ql-stroke {
        stroke: rgba($color-brand, 1);
      }
      .ql-stroke-miter {
        stroke: rgba($color-brand, 1);
      }
    }
  }

  .ql-snow {
    .ql-toolbar {
      button {
        &:hover {
          .ql-stroke {
            stroke: rgba($color-brand, 1);
          }
          .ql-stroke-miter {
            stroke: rgba($color-brand, 1);
          }
        }
        &:focus {
          .ql-stroke {
            stroke: rgba($color-brand, 1);
          }
          .ql-stroke-miter {
            stroke: rgba($color-brand, 1);
          }
        }
      }
      button.ql-active {
        .ql-stroke {
          stroke: rgba($color-brand, 1);
        }
        .ql-stroke-miter {
          stroke: rgba($color-brand, 1);
        }
      }
      .ql-picker-label {
        &:hover {
          .ql-stroke {
            stroke: rgba($color-brand, 1);
          }
          .ql-stroke-miter {
            stroke: rgba($color-brand, 1);
          }
        }
      }
      .ql-picker-label.ql-active {
        .ql-stroke {
          stroke: rgba($color-brand, 1);
        }
        .ql-stroke-miter {
          stroke: rgba($color-brand, 1);
        }
      }
      .ql-picker-item {
        &:hover {
          .ql-stroke {
            stroke: rgba($color-brand, 1);
          }
          .ql-stroke-miter {
            stroke: rgba($color-brand, 1);
          }
        }
      }
      .ql-picker-item.ql-selected {
        .ql-stroke {
          stroke: rgba($color-brand, 1);
        }
        .ql-stroke-miter {
          stroke: rgba($color-brand, 1);
        }
      }
    }
  }

  .ql-snow.ql-toolbar {
    button {
      &:hover {
        .ql-fill {
          fill: rgba($color-brand, 1);
        }
        .ql-stroke.ql-fill {
          fill: rgba($color-brand, 1);
        }
      }
      &:focus {
        .ql-fill {
          fill: rgba($color-brand, 1);
        }
        .ql-stroke.ql-fill {
          fill: rgba($color-brand, 1);
        }
      }
    }
    button.ql-active {
      .ql-fill {
        fill: rgba($color-brand, 1);
      }
      .ql-stroke.ql-fill {
        fill: rgba($color-brand, 1);
      }
    }
    .ql-picker-label {
      &:hover {
        .ql-fill {
          fill: rgba($color-brand, 1);
        }
        .ql-stroke.ql-fill {
          fill: rgba($color-brand, 1);
        }
      }
    }
    .ql-picker-label.ql-active {
      .ql-fill {
        fill: rgba($color-brand, 1);
      }
      .ql-stroke.ql-fill {
        fill: rgba($color-brand, 1);
      }
    }
    .ql-picker-item {
      &:hover {
        .ql-fill {
          fill: rgba($color-brand, 1);
        }
        .ql-stroke.ql-fill {
          fill: rgba($color-brand, 1);
        }
      }
    }
    .ql-picker-item.ql-selected {
      .ql-fill {
        fill: rgba($color-brand, 1);
      }
      .ql-stroke.ql-fill {
        fill: rgba($color-brand, 1);
      }
    }
  }
  .ql-snow {
    .ql-toolbar {
      button {
        &:hover {
          .ql-fill {
            fill: rgba($color-brand, 1);
          }
          .ql-stroke.ql-fill {
            fill: rgba($color-brand, 1);
          }
        }
        &:focus {
          .ql-fill {
            fill: rgba($color-brand, 1);
          }
          .ql-stroke.ql-fill {
            fill: rgba($color-brand, 1);
          }
        }
      }
      button.ql-active {
        .ql-fill {
          fill: rgba($color-brand, 1);
        }
        .ql-stroke.ql-fill {
          fill: rgba($color-brand, 1);
        }
      }
      .ql-picker-label {
        &:hover {
          .ql-fill {
            fill: rgba($color-brand, 1);
          }
          .ql-stroke.ql-fill {
            fill: rgba($color-brand, 1);
          }
        }
      }
      .ql-picker-label.ql-active {
        .ql-fill {
          fill: rgba($color-brand, 1);
        }
        .ql-stroke.ql-fill {
          fill: rgba($color-brand, 1);
        }
      }
      .ql-picker-item {
        &:hover {
          .ql-fill {
            fill: rgba($color-brand, 1);
          }
          .ql-stroke.ql-fill {
            fill: rgba($color-brand, 1);
          }
        }
      }
      .ql-picker-item.ql-selected {
        .ql-fill {
          fill: rgba($color-brand, 1);
        }
        .ql-stroke.ql-fill {
          fill: rgba($color-brand, 1);
        }
      }
    }
  }
}
