/////////////////////////
// Date Picker
/////////////////////////

.form-datetimepicker {
  position: relative;

  .react-date-picker {
    display: flex;

    &__inputGroup {
      outline: none;
      height: auto;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: var(--text-white);
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      border-radius: 0.25rem;
      border: 1px solid var(--border-neutral);
      background: inherit;
      display: flex;
      padding: 0.625rem 0.75rem;
      align-items: center;

      &:hover {
        border-color: var(--border-primary-active);
      }

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        user-select: none;
        min-width: auto;

        &::-webkit-input-placeholder {
          color: var(--text-white);
          opacity: 0.75;
        }

        &:-ms-input-placeholder {
          color: var(--text-white);
          opacity: 0.75;
        }

        &::placeholder {
          color: var(--text-white);
          opacity: 0.75;
        }

        &:invalid {
          background: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type='number'] {
          -moz-appearance: textfield;
        }
      }

      &__input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        min-width: 30px;
        font-size: 0.8125rem;
        line-height: 1.25rem;
        font-weight: 400;
        color: var(--text-white);
        user-select: none;
        height: auto;
      }
    }

    &--disabled {
      background: none;
    }

    &__calendar {
      margin-top: 0.25rem;
      overflow: hidden;
      z-index: 3;
      border-radius: 0.5rem;
      border: 1px solid var(--border-neutral);
      background: var(--background-inverse);
      box-shadow: 0px 4px 6px -2px rgba(34, 34, 34, 0.03),
        0px 12px 16px -4px rgba(34, 34, 34, 0.08);
    }

    &__wrapper {
      display: block;
      border: none;
    }

    &__input-container {
      display: block;
    }

    &-popper {
      z-index: 2;
    }
  }
}

.react-calendar {
  border: none;
  max-width: none;
  background: none;
  font-size: 0.8125rem;
  line-height: 1.4375rem;
  font-weight: 400;
  color: var(--text-white);
  background: var(--background-inverse);

  &__navigation {
    height: auto;
    margin: 0;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
    gap: 0.625rem;

    &__next2-button,
    &__prev2-button {
      display: none;
    }
    button {
      &:enabled:focus,
      &:enabled:hover {
        background: none;
      }
      &:disabled {
        background: none;
        opacity: 0.5;
      }
    }

    &__label {
      flex-grow: 0 !important;
      padding: 0;
      color: var(--text-white);
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      :after {
        @include materialsymbol('\e313');
        width: 1.25rem;
        height: 1.25rem;
        float: right;
      }
      &__labelText {
        display: block;
      }
    }

    &__arrow {
      color: var(--text-white);
      font-size: 1.5rem;
      line-height: 2rem;
      padding: 0;
      width: 2rem;
    }
  }

  &__month-view {
    &__weekdays {
      &__weekday {
        padding: 0.25rem 0.5rem;
      }
      color: var(--text-weak);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      background-color: var(--background-light);
      text-transform: none;
      abbr {
        text-decoration: none;
        cursor: default;
      }
    }

    &__days {
      &__day {
        color: var(--text-white);
        font-weight: 600;

        &--neighboringMonth {
          color: var(--text-disabled);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar {
      &__tile {
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 600;
        color: var(--text-white);
      }
    }
  }

  &__tile {
    height: 2.5rem;



    &--now {
      background: var(--background-primary);
      color: var(--text-white);
      border-radius: 0.5rem;

      &:disabled {
        background: var(--organizations-salmon) !important;
        color: var(--text-white) !important;
        border-radius: 0.5rem;
      }
    }

    &--active,
    &--hasActive {
      background: var(--brand-primary);
      color: var(--text-white);
      border-radius: 0.5rem;
    }

    &:enabled:focus {
      background: none;
    }

    &:enabled:hover {
      background: var(--background-primary-hover);
      color: var(--text-white);
      border-radius: 0.5rem;
    }

    &:disabled {
      background: none;
      color: var(--text-disabled);
    }
  }
}

.fixed-input {
  .react-select__control {
    width: 150px;
  }
}
