/////////////////////////
// Chart Box
/////////////////////////

.chart>ul *::after,
.chart>ul *::before {
    border-color: $color-white !important;
}

.chartBox {
    display: inline-flex;
    vertical-align: top;
    flex-direction: column;
    align-items: center;
    border: none;
    @include radius(.25rem);
    padding: .5rem;
    @include font__size(13px);
    @include line__height(16px);
    font-weight: $font-weight--500;
    background: $color-brand;
    color: $color-white;

    &__role {
        font-weight: $font-weight--700;
        margin-bottom: .25rem;
    }
}

.qualifiedIndividual {
    margin-top: 20px;
}