/////////////////////////
// Accordion
/////////////////////////

.accordion {
    border: none;
    @include radius(0px);

    &-item {
        background: none;
        border: none;

        &:not(:first-child) {
            margin-top: .25rem
        }
    }

    &-header {
        margin: 0;
    }

    &-button {
        @include font__size(14px);
        @include line__height(24px);
        font-weight: $font-weight--600;
        color: $color-white;
        text-transform: uppercase;
        padding: .75rem 1rem;
        background: $gray-700;
        box-shadow: none;

        &:focus {
            box-shadow: none;
        }

        &:after {
            display: none;
        }

        &:before {
            @include fontawesome('\f105');
            flex-shrink: 0;
            @include font__size(20px);
            margin-right: 1rem;
            margin-top: -1px;
            @include transition($transition);
        }

        &:not(.collapsed) {
            color: $color-white;
            background: $gray-700;
            box-shadow: none;

            &:before {
                @include rotate(90deg);
            }
        }
    }

    &-body {
        background: $gray-700;
        padding: .25rem 1rem 1rem;
        color: $color-white;
    }

    &.dark {
        .accordion {

            &-button,
            &-body {
                background: rgba($gray-900, .5);
            }
        }
    }

    &.captalize-title {
        .accordion {
            &-button {
                text-transform: capitalize;
            }
        }
    }
}
