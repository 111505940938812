//////////////////////////////////////
// Media Module Social Pages
//////////////////////////////////////

// Variable
$color-socials: #3A3A3A;
$color-socials--light: #E7E7E7;
$color-blue: #3498DB;
$color-hr: #C6C6C6;
$color-facebook: #3B5998;
$color-others: #D32F2F;
$color-ukrnews: #AFB42B;
$color-vk: #0077FF;

// Global

.site-main__social {

    &,
    & *:not(svg, svg path) {
        color: $color-socials;
    }

    .color-blue {
        color: $color-blue !important;
    }

    hr {
        border-color: $color-hr;
        border-top-width: 1px;
    }

    .avatar {
        height: 3.5rem;
        width: 3.5rem;
        @include radius(50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(../../../images/socials/icon__avatar.svg) no-repeat 0 0;
        background-size: 100%;
        border: none;

        img {
            height: 100%;
            width: 100%;
            display: block;
        }

        &:before {
            display: none;
        }
    }
}



// YouTube
.youtube {

    &__header,
    &__main {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            & {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        border-bottom: solid 2px $color-socials--light;
        position: relative;

        .button--icon {
            border-color: $color-socials--light;
            width: auto;
            height: 2.25rem;
            padding: 0 1rem;
            @include radius(1.5rem);

            img {
                height: .875rem;
            }

            &:hover {
                background: $color-socials--light;
            }
        }

        .logo {
            margin-left: 2rem;
            height: auto;

            img {
                height: 2rem;
                width: auto;
                display: block;
            }

            @include media-breakpoint-down(md) {
                & {
                    margin-left: 1rem;
                }
            }
        }

        .search {
            position: relative;
            margin-left: 3rem;
            width: 300px;

            @include media-breakpoint-down(xl) {
                width: 200px;
            }

            @include media-breakpoint-down(md) {
                display: none !important;
            }

            .icon {
                position: absolute;
                left: 1rem;
                top: 11px;
            }

            input {
                border: solid 2px $color-socials--light;
                outline: none;
                background: none;
                box-shadow: none;
                @include font-size(13px);
                @include line__height(20px);
                font-weight: $font-weight--400;
                color: $color-socials;
                padding: .375rem 1rem;
                padding-left: calc(2rem + 14px);
                @include radius(.25rem);
                width: 100%;

                &[type=search]::-ms-clear {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type=search]::-ms-reveal {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type="search"]::-webkit-search-decoration,
                &[type="search"]::-webkit-search-cancel-button,
                &[type="search"]::-webkit-search-results-button,
                &[type="search"]::-webkit-search-results-decoration {
                    display: none;
                }
            }
        }

        &>a {
            color: $color-blue;
            font-weight: $font-weight--500;
            text-transform: uppercase;
            margin-left: auto;

            &:hover {
                color: darken($color-blue, 10%);
            }

            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }
    }

    &__main {
        .mask {
            height: .5rem;
            width: 100%;
            display: flex;
            @include radius(1rem);
            background: $color-socials--light;

            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }

    .placeholder {
        background: $color-socials--light;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: default;
        opacity: 1;
        min-height: auto;
        width: 100%;
        height: 500px;
        @include radius(0);

        img {
            height: 6rem;
            width: auto;
        }

        span {
            @include font__size(16px);
            @include line__height(24px);
            color: $color-black;
            margin-top: 1rem;
        }

        &.sml {
            @include radius(.25rem);
            height: 6rem;
            width: 10rem;

            img {
                height: 1.5rem;
            }

            span {
                @include font__size(10px);
                @include line__height(14px);
                margin-top: .5rem;
            }
        }
    }

    &__button {
        background: $color-socials--light;
        @include font__size(12px);
        @include line__height(20px);
        @include radius(1rem);
        font-weight: $font-weight--500;
        display: inline-flex;
        align-items: center;
        vertical-align: top;
        outline: none;
        box-shadow: none;
        outline: none;
        padding: 7px 1rem;
        user-select: none;
        cursor: pointer;

        img {
            height: 1rem;
            width: auto;
        }

        &:hover {
            background: darken($color-socials--light, 2%);
        }
    }
}

// Instagram 
.instagram {

    &__header,
    &__main {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            & {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 2px $color-socials--light;
        position: relative;

        .button--icon {
            border-color: $color-socials--light;
            width: auto;
            height: 2.25rem;
            padding: 0 1rem;
            @include radius(1.5rem);

            img {
                height: .875rem;
            }

            &:hover {
                background: $color-socials--light;
            }
        }

        .logo {
            margin-left: 2rem;
            height: auto;

            img {
                height: 2.25rem;
                width: auto;
                display: block;
            }

            @include media-breakpoint-down(md) {
                & {
                    margin-left: 1rem;
                }
            }
        }

        .search {
            position: relative;
            width: 300px;

            @include media-breakpoint-down(xl) {
                width: 200px;
            }

            @include media-breakpoint-down(xl) {
                display: none !important;
            }

            .icon {
                position: absolute;
                left: 1rem;
                top: 11px;
            }

            input {
                border: solid 2px $color-socials--light;
                outline: none;
                background: none;
                box-shadow: none;
                @include font-size(13px);
                @include line__height(20px);
                font-weight: $font-weight--400;
                color: $color-socials;
                padding: .375rem 1rem;
                padding-left: calc(2rem + 14px);
                @include radius(.25rem);
                width: 100%;

                &[type=search]::-ms-clear {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type=search]::-ms-reveal {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type="search"]::-webkit-search-decoration,
                &[type="search"]::-webkit-search-cancel-button,
                &[type="search"]::-webkit-search-results-button,
                &[type="search"]::-webkit-search-results-decoration {
                    display: none;
                }
            }
        }

        .link-group {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(xl) {
                display: none !important;
            }

            a {
                color: $color-blue;
                font-weight: $font-weight--500;

                &:not(:first-child) {
                    margin-left: 1rem;
                }

                &:hover {
                    color: darken($color-blue, 10%);
                }

                &.link-button {
                    background: $color-blue;
                    color: $color-white;
                    padding: 6px .75rem;
                    @include radius(5px);

                    &:hover {
                        background: darken($color-blue, 10%);
                    }
                }
            }
        }
    }
}

// Facebook
.facebook {

    &__header,
    &__main {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            & {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &__header {
        padding-top: 21px;
        padding-bottom: 21px;
    }


    &__header {
        background: $color-facebook;
        display: flex;
        align-items: center;

        .button--icon {
            border-color: $color-white;
            background: $color-white;
            width: auto;
            height: 2.25rem;
            padding: 0 1rem;
            @include radius(1.5rem);

            img {
                height: .875rem;
            }

            &:hover {
                background: $color-socials--light;
                border-color: $color-socials--light;
            }
        }

        .logo {
            margin-left: 2rem;
            height: auto;

            img {
                height: 2rem;
                width: auto;
                display: block;
            }

            @include media-breakpoint-down(md) {
                & {
                    margin-left: 1rem;
                }
            }
        }

        .form {
            @include media-breakpoint-down(md) {
                display: none !important;
            }

            label {
                display: block;
                color: $color-white;
                @include font__size(13px);
                @include line__height(20px);
                font-weight: $font-weight--500;
                margin-bottom: .25rem;
            }

            button {
                background: $color-blue;
                color: $color-white;
                padding: 6px .75rem;
                @include radius(5px);
                border: none;
                outline: none;
                box-shadow: none;

                &:hover {
                    background: darken($color-blue, 10%);
                }
            }

            input {
                border: none;
                outline: none;
                background: #FAFFBD;
                box-shadow: none;
                @include font-size(13px);
                @include line__height(20px);
                font-weight: $font-weight--400;
                color: $color-socials;
                padding: .5rem 1rem;

                @include media-breakpoint-down(xl) {
                    width: 150px;
                }

                &[type=search]::-ms-clear {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type=search]::-ms-reveal {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type="search"]::-webkit-search-decoration,
                &[type="search"]::-webkit-search-cancel-button,
                &[type="search"]::-webkit-search-results-button,
                &[type="search"]::-webkit-search-results-decoration {
                    display: none;
                }
            }
        }
    }

    &__main {
        .comment {
            border: solid 1px $color-hr;
            outline: none;
            background: $color-white;
            box-shadow: none;
            @include font-size(13px);
            @include line__height(20px);
            font-weight: $font-weight--400;
            color: $color-socials;
            padding: .75rem 1rem;

            &[type=search]::-ms-clear {
                display: none;
                width: 0;
                height: 0;
            }

            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }
}

// Twitter
.twitter {

    &__header,
    &__main {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            & {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: solid 2px $color-white;

        .button--icon {
            border-color: $color-socials--light;
            width: auto;
            height: 2.25rem;
            padding: 0 1rem;
            @include radius(1.5rem);

            img {
                height: .875rem;
            }

            &:hover {
                background: $color-socials--light;
            }
        }

        .logo {
            margin-left: 2rem;
            height: auto;

            img {
                height: 2rem;
                width: auto;
                display: block;
            }

            @include media-breakpoint-down(md) {
                & {
                    margin-left: 1rem;
                }
            }
        }

        .search {
            position: relative;
            margin-left: auto;
            width: 300px;

            @include media-breakpoint-down(md) {
                display: none !important;
            }

            .icon {
                position: absolute;
                left: 1rem;
                top: 11px;
            }

            input {
                border: solid 2px $color-socials--light;
                outline: none;
                background: none;
                box-shadow: none;
                @include font-size(13px);
                @include line__height(20px);
                font-weight: $font-weight--400;
                color: $color-socials;
                padding: .375rem 1rem;
                padding-left: calc(2rem + 14px);
                @include radius(.25rem);
                width: 100%;

                &[type=search]::-ms-clear {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type=search]::-ms-reveal {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type="search"]::-webkit-search-decoration,
                &[type="search"]::-webkit-search-cancel-button,
                &[type="search"]::-webkit-search-results-button,
                &[type="search"]::-webkit-search-results-decoration {
                    display: none;
                }
            }
        }
    }

    &__main {
        background: $color-socials--light;
        min-height: calc(100vh - 102px);
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media-breakpoint-down(md) {
            & {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
        }
    }
}

// Others
.others {

    &__header,
    &__main {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            & {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &__header {
        background: $color-others;
        display: flex;
        align-items: center;
        border-bottom: solid 2px $color-others;

        .button--icon {
            border-color: $color-white;
            background: $color-white;
            width: auto;
            height: 2.25rem;
            padding: 0 1rem;
            @include radius(1.5rem);

            img {
                height: .875rem;
            }

            &:hover {
                background: $color-socials--light;
                border-color: $color-socials--light;
            }
        }

        .logo {
            margin-left: 2rem;
            height: auto;

            @include media-breakpoint-down(md) {
                & {
                    margin-left: 1rem;
                }
            }

            img {
                height: 2.25rem;
                width: auto;
                display: block;
            }
        }
    }

    &__main {
        background: $color-socials--light;
        min-height: calc(100vh - 102px);
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media-breakpoint-down(md) {
            & {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
        }

        .bg-white {
            position: relative;

            @include media-breakpoint-up(md) {
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    border-right: 16px solid $color-white;
                    position: absolute;
                    right: 100%;
                    top: 1rem;
                }
            }

            @include media-breakpoint-down(md) {
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 16px solid $color-white;
                    position: absolute;
                    bottom: 100%;
                    left: 1rem;
                }
            }
        }
    }
}

// UKR News
.ukrnews {

    &__header,
    &__main {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            & {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &__header {
        background: $color-ukrnews;
        display: flex;
        align-items: center;
        padding-top: 23px;
        padding-bottom: 23px;

        .button--icon {
            border-color: $color-white;
            background: $color-white;
            width: auto;
            height: 2.25rem;
            padding: 0 1rem;
            @include radius(1.5rem);

            img {
                height: .875rem;
            }

            &:hover {
                background: $color-socials--light;
                border-color: $color-socials--light;
            }
        }

        .logo {
            margin-left: 2rem;
            display: flex;
            align-items: center;
            flex-grow: 1;
            height: auto;
            padding: .5rem 1rem;

            // &>span {
            //     width: 40px;
            //     height: 40px;
            //     @include radius(50%);
            //     background: $color-blue;
            //     margin-right: 1rem;
            // }

            @include media-breakpoint-down(md) {
                & {
                    margin-left: 1rem;
                }
            }
        }
    }

    &__main {
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media-breakpoint-down(md) {
            & {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
        }
    }
}

// VK
.vk {

    &__header,
    &__main {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            & {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &__header {
        background: $color-vk;
        display: flex;
        align-items: center;
        border-bottom: solid 2px $color-vk;

        .button--icon {
            border-color: $color-white;
            background: $color-white;
            width: auto;
            height: 2.25rem;
            padding: 0 1rem;
            @include radius(1.5rem);

            img {
                height: .875rem;
            }

            &:hover {
                background: $color-socials--light;
                border-color: $color-socials--light;
            }
        }

        .logo {
            margin-left: 2rem;
            height: auto;

            img {
                height: 2rem;
                width: auto;
                display: block;
            }

            @include media-breakpoint-down(md) {
                & {
                    margin-left: 1rem;
                }
            }
        }

        .search {
            position: relative;
            margin-left: 3rem;
            width: 300px;

            @include media-breakpoint-down(md) {
                display: none !important;
            }

            .icon {
                position: absolute;
                left: 1rem;
                top: 11px;
            }

            input {
                border: none;
                outline: none;
                background: $color-white;
                box-shadow: none;
                @include font-size(13px);
                @include line__height(20px);
                font-weight: $font-weight--400;
                color: $color-socials;
                padding: .5rem 1rem;
                padding-left: calc(2rem + 14px);
                @include radius(2rem);
                width: 100%;

                &[type=search]::-ms-clear {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type=search]::-ms-reveal {
                    display: none;
                    width: 0;
                    height: 0;
                }

                &[type="search"]::-webkit-search-decoration,
                &[type="search"]::-webkit-search-cancel-button,
                &[type="search"]::-webkit-search-results-button,
                &[type="search"]::-webkit-search-results-decoration {
                    display: none;
                }
            }
        }

        &>a {
            color: $color-white !important;
            font-weight: $font-weight--500;
            margin-left: auto;

            &:hover {
                color: $color-white;
            }

            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }
    }

    &__main {
        .comment {
            border: solid 1px $color-hr;
            outline: none;
            background: $color-white;
            box-shadow: none;
            @include font-size(13px);
            @include line__height(20px);
            font-weight: $font-weight--400;
            color: $color-socials;
            padding: .75rem 1rem;

            &[type=search]::-ms-clear {
                display: none;
                width: 0;
                height: 0;
            }

            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }
}