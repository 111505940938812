/////////////////////////
// Steps
/////////////////////////

.steps {
  & > .row {
    display: flex;
    justify-content: center;
    margin-left: 8%;
    align-items: center;
  }

  .step-marker {
    width: 24px;
    height: 24px;
    @include radius(24px);
    padding: 0.625rem;
    background: var(--neutral-900);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #fff;
    font-weight: $font-weight--600;
    margin: 0 0.75rem;
    position: relative;
    z-index: 1;

    img {
      fill: var(--background-primary);
    }

    @include media-breakpoint-down(md) {
      & {
        margin: 0 0.25rem 0 0;
      }
    }
  }

  .step-item {
    position: relative;
    padding: 0;

    & > span,
    a {
      display: flex;
      justify-content: start;
      align-items: center;
      overflow: hidden;
    }
  }

  .step-last-content {
    width: fit-content;
  }

  .step-divider {
    height: 0.1rem;
    width: 100%;
    background: var(--neutral-500);
    @include media-breakpoint-down(md) {
      & {
        display: none;
      }
    }
  }

  .step-title {
    color: var(--neutral-400);
    font-weight: $font-weight--600;
    text-align: center;
    @include font__size(16px);
    display: inline-block;
    white-space: normal;
    padding-right: 0.625rem;
  }

  .step-completed {
    .step-divider {
      height: 0.125rem;
      background: var(--background-alt);
    }

    .step-title {
      font-weight: $font-weight--600;
      text-align: center;
      @include font__size(16px);
    }

    img {
      width: 1rem;
    }

    .step-marker {
      color: var(--background-primary) !important;
      border: 1px solid var(--background-primary) !important;
      fill: var(--background-primary) !important;
    }
  }

  .step-current {
    .step-divider {
      height: 0.125rem;
      background: var(--background-alt);
    }

    .step-title {
      color: var(--background-alt);
    }

    .step-marker {
      color: var(--background-alt);
      background-color: var(--background-primary);
      border-color: var(--background-primary);

      img {
        filter: none;
      }
    }
  }
}
